<template>
  <div>
    <slot
        name="activator"
        :on="on"
    ></slot>
    <v-dialog v-model="rejectDialog" max-width="600px">
      <v-card>
        <v-card-title class="pa-4">
          <div class="text-h5 font-weight-bold force-break-word">{{ $t('TITLE_REJECTION_DIALOG') }}</div>
          <div class="text-h6 font-weight-light force-break-word">{{ $t('SUBTITLE_REJECTION_DIALOG') }}</div>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-list>
              <v-list-item
                  v-for="invoice of selectedInvoices"
                  :key="invoice.id">
                <v-list-item-title>
                  {{ $t('REJECTED_DOCUMENT') }} {{ invoice.number }} / {{ invoice.amountWithTax | currency(selectedDebtorAccount.currency) }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-select
                :items="Object.values(rejectionEnumeration)"
                v-model="selectedReason"
                :rules="[rules.requiredFieldRules]"
                :label="$t('LABEL_REASON_HEADER')"
                class="px-4">
              <template v-slot:item="{ item }">
                <span> {{ $t(item) }} </span>
              </template>

              <template v-slot:selection="{ item }">
                <span> {{ $t(item) }} </span>
              </template>
            </v-select>
            <v-text-field
                v-model="rejectionDescription"
                :rules="rejectionDescriptionRules"
                :label="$t('LABEL_REASON_DESCRIPTION')"
                :required="optionOtherSelected"
                counter="250"
                class="px-4"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row
              justify="space-around">
            <v-btn
                color="error"
                @click="close"
                class="my-4"
            >
              {{ $t('ACTION_CANCEL') }}
            </v-btn>
            <v-btn
                :disabled="!valid"
                color="primary"
                @click="validate"
                class="my-4"
            >
              {{ $t('ACTION_CONFIRM') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import invoiceService from '@/services/invoiceService';
import invoiceMapper from '@/mappers/invoiceMapper';
import numberMixin from '@/mixins/numberMixin';
import userMixin from '@/mixins/userMixin';
import ruleMixin from '@/mixins/ruleMixin';
import eventMixin from '@/mixins/eventMixin';
import { mapState } from 'vuex';

export default {
  name: 'InvoicesRejectionDialog',
  mixins: [numberMixin, userMixin, ruleMixin, eventMixin],
  data () {
    return {
      on: {
        click: this.open
      },
      rejectDialog: false,
      valid: false,
      selectedInvoices: [],
      selectedReason: undefined,
      rejectionDescription: undefined,
      rejectionDescriptionRules: [
        this.requiredDescriptionRule,
        v => !v || v === '' || v.length <= 250 || this.$t('ERROR_DESCRIPTION_OVER_250')
      ]
    };
  },
  props: {
    invoicesToReject: {
      type: Array,
      default: () => []
    },
    privateEventBus: Object
  },
  methods: {
    async validate () {
      try {
        const mappedRejectedInvoices = this.selectedInvoices.map(invoice => invoiceMapper.mapToInvoiceDtoV2(invoice));
        const rejectionRequest = {
          userId: this.user.id,
          invoiceList: mappedRejectedInvoices,
          reason: this.selectedReason,
          rejectionDescription: this.rejectionDescription
        };
        await invoiceService.rejectInvoices(rejectionRequest);
        this.displaySuccessSnackbar(this.$t('SUCCESS_MESSAGE_INVOICES_REJECTION'));
        this.privateEventBus.$emit('reloadInvoicesList');
        this.close();
      } catch (err) {
        this.handleError(err);
      }
    },
    requiredDescriptionRule (value) {
      if (this.optionOtherSelected) {
        return !!value || this.$t('ERROR_REQUIRED_FIELD');
      }
      return true;
    },
    open ($event) {
      this.selectedInvoices = this.invoicesToReject.filter(({ status }) => status === 'TO_VALIDATE');
      this.rejectDialog = true;
    },
    close () {
      this.$refs.form.reset();
      this.rejectDialog = false;
    }
  },
  computed: {
    ...mapState(['selectedDebtorAccount']),
    optionOtherSelected () {
      return this.selectedReason === this.rejectionEnumeration.REJECTION_REASON_OTHER;
    }
  },
  watch: {
    optionOtherSelected () {
      this.$refs.form.validate();
    }
  }
};
</script>