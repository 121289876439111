import Vue from 'vue';
import eventBus from '@/events';
import { mapState } from 'vuex';
import SelectableInvoicesTable from '@/components/debtor/invoices/SelectableInvoicesTable';
import BottomBar from '@/components/debtor/invoices/BottomBar';

export default {
  components: {
    SelectableInvoicesTable,
    BottomBar
  },
  data () {
    return {
      invoices: [],
      loading: false,
      selectedDocuments: [],
      invoicesToValidate: [],
      invoicesToReject: [],
      privateEventBus: new Vue()
    };
  },
  async beforeMount () {
    await this.getInvoices(this.selectedDebtorAccount.billingAccountId);
    this.privateEventBus.$on('reloadInvoicesList', () => this.resetPage());
  },
  computed: {
    ...mapState(['creditor', 'selectedDebtorAccount']),
    isDocumentSelection () {
      return this.selectedDocuments.length !== 0;
    }
  },
  methods: {
    resetSelectedDocuments () {
      this.selectedDocuments = [];
      eventBus.$emit('footer:shrink');
    },
    resetPage () {
      this.getInvoices(this.selectedDebtorAccount.billingAccountId);
      this.resetSelectedDocuments();
    }
  },
  watch: {
    async selectedDebtorAccount () {
      await this.getInvoices(this.selectedDebtorAccount.billingAccountId);
    },
    isDocumentSelection (value) {
      if (value) {
        eventBus.$emit('footer:extend');
      } else {
        eventBus.$emit('footer:shrink');
      }
    }
  },
  beforeDestroy () {
    this.resetSelectedDocuments();
  }
};