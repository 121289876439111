<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <SelectableInvoicesTable
          :privateEventBus="privateEventBus"
          :loading="loading"
          :invoices="invoices"
          :selectedDebtorAccount="selectedDebtorAccount"
          @changeSelectedDocuments="selectedDocuments = $event">
        </SelectableInvoicesTable>
      </v-col>
    </v-row>
    <BottomBar
        :privateEventBus="privateEventBus"
        :selectedDocuments="selectedDocuments"
        :selectedDebtorAccount="selectedDebtorAccount"
        :isDocumentSelection="isDocumentSelection">
    </BottomBar>
  </v-container>
</template>

<script>
import invoiceService from '@/services/invoiceService';
import invoiceMapper from '@/mappers/invoiceMapper';
import invoiceMixin from '@/mixins/invoiceMixin';

export default {
  mixins: [invoiceMixin],
  methods: {
    async getInvoices (debtorBillingAccountId) {
      this.loading = true;
      try {
        const { data } = await invoiceService.getInvoicesToPay(debtorBillingAccountId, this.selectedDebtorAccount.debtorSettings.VALIDATION_REQUIRED.value);
        this.invoices = invoiceMapper.mapAll(data.content);
        this.selectedDebtorAccount.toPayInvoicesCount = data.totalElements;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
