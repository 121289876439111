<template>
  <!-- TODO: replace with v-bottom-sheet Vuetify component when feature request will be implemented -->
  <!-- https://github.com/vuetifyjs/vuetify/issues/6495 -->
  <!-- Displayed bottomBar when user isn't on mobile -->
  <transition name="bottom-sheet-transition">
    <div v-if="isDocumentSelection" role="document" tabindex="0" class="v-dialog__content v-dialog__content--active custom-bottom-sheet-wrapper">
      <div class="v-dialog v-dialog--active v-dialog--persistent v-bottom-sheet d-flex custom-bottom-sheet">
        <v-container class="d-flex py-0 align-center">
          <div class="d-block align-self-center" :class="isOnMobileSupport ? 'col-5' : 'col-4'">
            <div class="font-weight-bold" :class="isOnMobileSupport ? 'text-h8' : 'text-h6'">{{ selectedDocuments.length || $t('NO') }} {{ $tc('DOCUMENT_SELECTED', selectedDocuments.length) }}</div>
            <div>{{ $t('LABEL_TOTAL') }} {{ totalSelectionAmount | currency(selectedDebtorAccount.currency) }}</div>
          </div>
          <!-- Details are displayed instantly if the mobile phone screen is rotated as there is enough space to show it -->
          <div v-if="!isOnMobileSupport || isScreenRotated" class="d-block align-self-center" :class="isOnMobileSupport ? 'col-5' : 'col-4'">
            <div>{{ $t('SELECTED_INVOICES_AMOUNT') }} {{ totalInvoicesAmount | currency(selectedDebtorAccount.currency) }}</div>
            <div>{{ $t('SELECTED_CREDIT_NOTES_AMOUNT') }} {{ -totalCreditNotesAmount | currency(selectedDebtorAccount.currency) }}</div>
          </div>
          <!-- Details are displayed through a tooltip button if the mobilephone screen isn't rotated -->
          <div v-else class="col-3 d-flex align-self-center justify-space-around">
            <v-tooltip
                open-on-click
                :open-on-hover="false"
                top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    small
                    class="mb-2"
                    dark
                    v-on="on"
                    v-bind="attrs"
                >
                  {{ $t('BUTTON_DETAILS') }}
                </v-btn>
              </template>
              <span>{{ $t('SELECTED_INVOICES_AMOUNT') }} {{ totalInvoicesAmount | currency(selectedDebtorAccount.currency) }}</span>
              <v-spacer></v-spacer>
              <span>{{ $t('SELECTED_CREDIT_NOTES_AMOUNT') }} {{ -totalCreditNotesAmount | currency(selectedDebtorAccount.currency) }}</span>
            </v-tooltip>
          </div>
          <!-- class isn't the same with screen rotated as the object before those buttons can be a button with a tooltip containing the details
          or the details displayed without tooltip -->
          <div v-if="isOnMobileSupport" class="d-flex justify-space-around align-self-center" :class="isScreenRotated ? 'col-2' : 'col-5'">
            <v-btn
                color="secondary"
                small
                class="mb-1"
                @click="payInvoices(selectedDocuments)"
                :disabled="!enablePayment">
              {{ $t('ACTION_PAY') }}
            </v-btn>
            <InvoicesRejectionDialog
                :privateEventBus="privateEventBus"
                :invoicesToValidate="selectedDocuments">
              <template v-slot:activator="{ on: rejectDialog, attrs: rejection }">
                <InvoicesValidationDialog
                    :privateEventBus="privateEventBus"
                    :invoicesToValidate="selectedDocuments">
                  <template v-slot:activator="{ on: validationDialog, attrs: validation }">
                    <v-menu
                        bottom
                        right
                        offset-y
                    >
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="{ ...menu }"
                        >
                          <v-icon color="primary" class="mb-2">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list class="py-0">
                        <v-list-item class="text-center"
                                     v-on="{ ...rejectDialog }"
                                     v-bind="{ ...rejection }"
                                     link>
                          <v-list-item-title v-text="$t('ACTION_REJECT')"></v-list-item-title>
                        </v-list-item>
                        <v-list-item class="text-center"
                                     v-if="toValidatePage"
                                     v-on="{ ...validationDialog }"
                                     v-bind="{ ...validation }"
                                     link>
                          <v-list-item-title v-text="$t('ACTION_VALIDATE')"></v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </InvoicesValidationDialog>
              </template>
            </InvoicesRejectionDialog>
          </div>
          <div v-else class="d-flex justify-end align-self-center col-4">
            <InvoicesRejectionDialog
                :privateEventBus="privateEventBus"
                :invoicesToReject="selectedDocuments">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-on="on"
                    v-bind="attrs"
                    text
                    color="secondary"
                    :disabled="!enableValidationOrRejection">
                  {{ $t('ACTION_REJECT') }}
                </v-btn>
              </template>
            </InvoicesRejectionDialog>
            <v-btn
                :outlined="toValidatePage"
                color="secondary"
                class="ml-4"
                @click="payInvoices(selectedDocuments)"
                :disabled="!enablePayment">
              {{ $t('ACTION_PAY') }}
            </v-btn>
            <InvoicesValidationDialog
                v-if="toValidatePage"
                :privateEventBus="privateEventBus"
                :invoicesToValidate="selectedDocuments">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-on="on"
                    v-bind="attrs"
                    class="ml-4"
                    color="secondary"
                    :disabled="!enableValidationOrRejection">
                  {{ $t('ACTION_VALIDATE') }}
                </v-btn>
              </template>
            </InvoicesValidationDialog>
          </div>
        </v-container>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import numberMixin from '@/mixins/numberMixin';
import userMixin from '@/mixins/userMixin';
import InvoicesValidationDialog from '@/components/debtor/invoices/InvoicesValidationDialog';
import InvoicesRejectionDialog from '@/components/debtor/invoices/InvoicesRejectionDialog';
import basketService from '@/services/basketService';

export default {
  name: 'BottomBar',
  components: {
    InvoicesValidationDialog,
    InvoicesRejectionDialog
  },
  data () {
    return {
      isScreenRotated: false
    };
  },
  mixins: [numberMixin, userMixin],
  props: {
    selectedDocuments: {
      type: Array,
      default: () => []
    },
    selectedDebtorAccount: {
      type: Object,
      default: () => {}
    },
    isDocumentSelection: {
      type: Boolean,
      default: false
    },
    toValidatePage: {
      type: Boolean,
      default: false
    },
    privateEventBus: Object
  },
  computed: {
    ...mapState(['creditor']),
    totalInvoicesAmount () {
      return this.selectedDocuments.filter(({ isInvoice }) => isInvoice)
        .map(({ temporaryOutstandingAmount }) => temporaryOutstandingAmount)
        .reduce(this.sumReducer, 0);
    },
    totalCreditNotesAmount () {
      return this.selectedDocuments.filter(({ isCreditNote }) => isCreditNote)
        .map(({ temporaryOutstandingAmount }) => temporaryOutstandingAmount)
        .reduce(this.sumReducer, 0);
    },
    totalSelectionAmount () {
      return this.totalInvoicesAmount - this.totalCreditNotesAmount;
    },
    someInvoiceToValidateInSelection () {
      return this.selectedDocuments.filter(({ status }) => status === 'TO_VALIDATE').length;
    },
    someInvoiceToPayInSelection () {
      return this.selectedDocuments.filter(({ status }) => status === 'TO_PAY').length;
    },
    hasValidationOrRejectionRights () {
      return this.creditor.creditorSettings?.VALIDATION_ENABLED.value && (this.isDebtorAdmin || this.isDebtorApprover);
    },
    hasPaymentRights () {
      return this.isDebtorAdmin || this.isDebtorPayer;
    },
    mustValidateInvoice () {
      return this.creditor.creditorSettings?.VALIDATION_ENABLED.value && this.selectedDebtorAccount.debtorSettings.VALIDATION_REQUIRED.value;
    },
    enableValidationOrRejection () {
      return this.creditor.creditorSettings?.VALIDATION_ENABLED.value && this.hasValidationOrRejectionRights && this.someInvoiceToValidateInSelection;
    },
    hasAtLeastAnInvoice () {
      return this.selectedDocuments.filter(({ isInvoice }) => isInvoice).length;
    },
    enablePayment () {
      return this.hasPaymentRights && this.hasAtLeastAnInvoice && (
        this.someInvoiceToPayInSelection || (
          this.someInvoiceToValidateInSelection && (
            !this.mustValidateInvoice || (
              this.mustValidateInvoice && this.hasValidationOrRejectionRights)))) &&
        (this.creditor.creditorSettings?.MULTIPLE_PAYMENT_ENABLED.value || this.selectedDocuments.length === 1);
    }
  },
  created () {
    window.addEventListener('resize', this.sizeHandler);
  },
  methods: {
    async payInvoices (documents) {
      const basket = basketService.createBasket({
        invoices: documents,
        accountId: this.selectedDebtorAccount.billingAccountId,
        creditorId: this.creditor.id
      });
      // store the basket
      await this.$store.dispatch('setBasket', basket);
      this.$router.push({ name: 'DebtorCheckout' });
    },
    // isScreenRotated can't be a computed as window.screen.height or width aren't automaticaly calculated
    sizeHandler () {
      this.isScreenRotated = window.screen.height < window.screen.width;
    }
  }
};
</script>

<style scoped>
.custom-bottom-sheet-wrapper {
  top: auto;
  height: auto;
  bottom: 0px;
  z-index: 202;
}

.custom-bottom-sheet {
  transform-origin: center center;
  height: 100px;
  background-color: white;
  align-self: flex-end;
  border-radius: 0;
  flex: 0 1 auto;
  margin: 0;
  overflow: visible;
}
</style>