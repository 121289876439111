<template>
  <v-tabs
    background-color="primary accent-4"
    fixed-tabs
    dark>
    <v-tab
      class="pt-2"
      v-for="(item, i) in menuItems"
      :key="i"
      :to="{ name: item.linkTo }"
      exact
    >
      <v-badge
        color="white"
        offset-x="-5"
        :value="item.badgeProperty != null && selectedDebtorAccount[item.badgeProperty]"
      >
        <template v-slot:badge>
          <span class="black--text">{{ selectedDebtorAccount[item.badgeProperty] }}</span>
        </template>
        {{ item.menuLabel }}
      </v-badge>
    </v-tab>
  </v-tabs>
</template>

<script>
import menuMixin from '@/mixins/menuMixin';
import userMixin from '@/mixins/userMixin';
import { mapState } from 'vuex';

export default {
  mixins: [userMixin, menuMixin],
  computed: {
    ...mapState(['selectedDebtorAccount'])
  }
};
</script>