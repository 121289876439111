import Service from './Service';

class PaymentOperationService extends Service {
  createPaymentOperation ({ basket, creditorId, debtorId, paymentMean, languageCode, directDebitDate, umr }) {
    const documents = basket.invoices.map(invoice => ({
      amount: invoice.balance.amount,
      invoiceId: invoice.id
    }));

    return this.instance.post('invoiceapi/v1/payment-operations', {
      amount: basket.balance.amount,
      paymentMeanType: paymentMean.type,
      paymentMeanId: paymentMean.id,
      creditorId,
      debtorId,
      languageCode,
      documents,
      directDebitDate,
      umr
    });
  }

  getPaymentOperationByTransactionReference (transactionReference) {
    return this.instance.get(`invoiceapi/v1/payment-operations/${transactionReference}`);
  }

  getPaymentOperationHistory (paymentOperationId) {
    return this.instance.get(`invoiceapi/v2/payment-operations-history/payment-operation/${paymentOperationId}`);
  }

  getPaymentOperationByInvoiceId (invoiceId) {
    return this.instance.get(`invoiceapi/v1/payment-operations/invoice/${invoiceId}`);
  }

  getPaginatedAndSortedPaymentsByDebtorId (debtorId, { sortBy, sortDesc, page, itemsPerPage }, searchFilter) {
    const sort = sortBy.length === 1 ? sortBy[0] + ',' + (sortDesc[0] ? 'DESC' : 'ASC') : '';
    return this.instance.get(`invoiceapi/public/v2/payment-operations/debtors/${debtorId}`, {
      params: {
        searchFilter,
        size: itemsPerPage,
        page: page - 1,
        sort
      }
    });
  }
}

export default new PaymentOperationService();
