<template>
  <v-card>
    <v-card-title>
      <ExportCsvButton
          :loadingData="loading"
          :headers="exportableHeaders"
          :invoices="invoices"
          :filename="toValidatePage ? $t('NAME_CSV_FILE_TO_VALIDATE') : $t('NAME_CSV_FILE_TO_PAY')">
      </ExportCsvButton>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="$t('SEARCH')"
          single-line
          hide-details
          clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
        v-model="selectedDocuments"
        :headers="headers"
        :items="invoices"
        :show-select="this.creditor.creditorSettings.MULTIPLE_PAYMENT_ENABLED.value"
        :loading="loading"
        :search="search"
        :sort-by.sync="defaultSortBy"
        sort-desc
        :custom-sort="tableCustomSort"
        @item-selected="processDocumentSelection($event)"
        @toggle-select-all="updateSelection($event)"
    >
      <template v-slot:header.data-table-select v-if="isOnMobileSupport">
        <!-- Do not display the select-all checkbox on mobile devices -->
      </template>
      <template v-slot:item.refDoc="{ item }">
        <InvoiceNumberWithDownloadLink :invoice="item"></InvoiceNumberWithDownloadLink>
      </template>
      <template v-slot:item.issueDate="{ item }">
        {{ item.issueDate | date }}
      </template>
      <template v-slot:item.externalRef="{ item }">
        <v-list
            v-if="item.linkedInvoices && item.linkedInvoices.length"
            class="transparent">
          <v-list-item
              v-for='invoice in item.linkedInvoices'
              :key='invoice.id'
              class="pa-0">
            <router-link :to="{
              name: 'DebtorInvoiceDetail',
              params: { id: invoice.id }
            }">
              <span class="text-caption">{{ invoice.number }}</span>
            </router-link>
          </v-list-item>
        </v-list>
      </template>
      <template v-slot:item.amountExclusiveOfTax="{ item }">
        {{ item.amountExclusiveOfTax | currency(item.currency) }}
      </template>
      <template v-slot:item.amountWithTax="{ item }">
        {{ item.amountWithTax | currency(item.currency) }}
      </template>
      <template v-slot:item.dueDate="{ item }">
        <DueDate v-bind:dueDate="item.dueDate"></DueDate>
      </template>
      <template v-slot:item.temporaryOutstandingAmount="{ item }">
        <BoldAndRelativeAmount
        :amount="item.temporaryOutstandingAmount"
        :isInvoice="item.isInvoice"
        :currency="item.currency"
      />
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-center">
          <InvoicesValidationDialog
              v-if="toValidatePage"
              :privateEventBus="privateEventBus"
              :invoicesToValidate="[ item ]">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                :disabled="isDebtorPayer"
                small
                color="primary">
                {{ $t('ACTION_VALIDATE') }}
              </v-btn>
            </template>
          </InvoicesValidationDialog>
          <v-btn
              v-else
              small
              color="primary"
              :disabled="item.isCreditNote"
              @click="payInvoice(item)">
            {{ $t('ACTION_PAY') }}
          </v-btn>
          <InvoicesRejectionDialog
              :privateEventBus="privateEventBus"
              :invoicesToReject="[ item ]">
            <template v-slot:activator="{ on: rejectDialog, attrs }">
              <v-menu
                  bottom
                  right
                  offset-y
              >
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="{ ...menu }"
                  >
                    <v-icon color="primary">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list class="py-0">
                  <v-list-item
                    class="text-center"
                    :to="{ name: 'DebtorInvoiceDetail', params: { id: item.id } }"
                    link>
                      <v-list-item-title v-text="$t('BUTTON_DETAILS')"></v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="item.status === 'TO_VALIDATE'"
                    class="text-center"
                    :disabled="isDebtorPayer"
                    v-on="{ ...rejectDialog }"
                    v-bind="attrs"
                    link>
                      <v-list-item-title v-text="$t('ACTION_REJECT')"></v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="toValidatePage && !selectedDebtorAccount.debtorSettings.VALIDATION_REQUIRED.value && item.isInvoice"
                    class="text-center"
                    @click="payInvoice(item)"
                    link>
                      <v-list-item-title v-text="$t('ACTION_PAY')"></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </InvoicesRejectionDialog>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import dateMixin from '@/mixins/dateMixin';
import InvoiceNumberWithDownloadLink from '@/components/common/buttons/InvoiceNumberWithDownloadLink';
import numberMixin from '@/mixins/numberMixin';
import userMixin from '@/mixins/userMixin';
import unionBy from 'lodash/unionBy';
import basketService from '@/services/basketService';
import DueDate from '@/components/common/DueDate';
import InvoicesValidationDialog from '@/components/debtor/invoices/InvoicesValidationDialog';
import InvoicesRejectionDialog from '@/components/debtor/invoices/InvoicesRejectionDialog';
import ExportCsvButton from '@/components/common/buttons/ExportCsvButton';
import BoldAndRelativeAmount from '@/components/common/BoldAndRelativeAmount';
import { mapState } from 'vuex';

export default {
  name: 'SelectableInvoicesTable',
  components: {
    DueDate,
    InvoicesValidationDialog,
    InvoicesRejectionDialog,
    ExportCsvButton,
    BoldAndRelativeAmount,
    InvoiceNumberWithDownloadLink
  },
  mixins: [dateMixin, numberMixin, userMixin],
  props: {
    invoices: {
      type: Array,
      default: () => []
    },
    selectedDebtorAccount: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    toValidatePage: {
      type: Boolean,
      default: false
    },
    privateEventBus: Object
  },
  data () {
    return {
      headers: [
        {
          text: this.$t('LABEL_DOCUMENT_REFERENCE'),
          value: 'refDoc',
          exportable: true
        },
        {
          text: this.$t('LABEL_ORDER_REFERENCE'),
          value: 'externalRef',
          sortable: false,
          filterable: false,
          exportable: true
        },
        {
          text: this.$t('INVOICE_ISSUE_DATE'),
          value: 'issueDate',
          filterable: false,
          align: 'center',
          exportable: true
        },
        {
          text: this.$t('LABEL_AMOUNT_EXCLUDING_TAXES'),
          value: 'amountExclusiveOfTax',
          filterable: false,
          align: 'end',
          exportable: true
        },
        {
          text: this.$t('LABEL_AMOUNT_INCLUDING_TAXES'),
          value: 'amountWithTax',
          filterable: false,
          align: 'end',
          exportable: true
        },
        {
          text: this.$t('INVOICE_DUE_DATE'),
          value: 'dueDate',
          filterable: false,
          align: 'center',
          exportable: true
        },
        {
          text: this.$t('LABEL_OUTSTANDING'),
          value: 'temporaryOutstandingAmount',
          filterable: false,
          align: 'end',
          exportable: true
        },
        {
          text: this.$t('LABEL_ACTIONS'),
          value: 'actions',
          sortable: false,
          filterable: false,
          exportable: false
        }
      ],
      search: '',
      defaultSortBy: 'dueDate',
      selectedDocuments: []
    };
  },
  beforeMount () {
    this.privateEventBus.$on('reloadInvoicesList', () => this.resetSelectedDocuments());
  },
  computed: {
    ...mapState(['creditor']),
    exportableHeaders () {
      return this.headers.filter(header => !!header.exportable);
    }
  },
  methods: {
    tableCustomSort (items, index, isDesc) {
      if (index.length === 0) {
        return items;
      }
      items.sort((a, b) => {
        if (index[0] === 'dueDate') {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index]);
          } else {
            return new Date(a[index]) - new Date(b[index]);
          }
        } else if (index[0] === 'temporaryOutstandingAmount') {
          const aTmpOutAmountValue = a.isInvoice ? -a[index] : a[index];
          const bTmpOutAmountValue = b.isInvoice ? -b[index] : b[index];
          if (!isDesc[0]) {
            return aTmpOutAmountValue > (bTmpOutAmountValue) ? 1 : -1;
          } else {
            return aTmpOutAmountValue < (bTmpOutAmountValue) ? 1 : -1;
          }
        } else if (typeof a[index] === 'number') {
          if (!isDesc[0]) {
            return a[index] > (b[index]) ? 1 : -1;
          } else {
            return a[index] < (b[index]) ? 1 : -1;
          }
        } else if (typeof a[index] !== 'undefined') {
          if (!isDesc[0]) {
            return a[index].toString().toLowerCase().localeCompare(b[index].toString().toLowerCase());
          } else {
            return b[index].toString().toLowerCase().localeCompare(a[index].toString().toLowerCase());
          }
        }
        return 1;
      });
      return items;
    },
    processDocumentSelection ({ item, value }) {
      if (value) {
        this.selectedDocuments.push(item);
        if (item.isInvoice) {
          this.addLinkedCreditNotesToSelection(item.linkedInvoices);
        }
      } else {
        this.removeFromSelectedDocuments(item.id);
        if (item.isInvoice) {
          this.removeLinkedCreditNotesFromSelection(item.id, item.linkedInvoices);
        }
      }
      this.$emit('changeSelectedDocuments', this.selectedDocuments);
    },
    addLinkedCreditNotesToSelection (creditNotes) {
      // We first control that extra creditNotes are included in invoice table
      const filteredCreditNotes = creditNotes.filter(({ id }) => this.invoices.some(invoice => invoice.id === id));
      this.selectedDocuments = unionBy(this.selectedDocuments, filteredCreditNotes, 'id');
    },
    removeLinkedCreditNotesFromSelection (removedInvoiceId, linkedCreditNotesList) {
      linkedCreditNotesList.forEach(({ id }) => {
        if (!this.isCreditNoteLinkedToStillSelectedInvoice(removedInvoiceId, id)) {
          this.removeFromSelectedDocuments(id);
        }
      });
    },
    isCreditNoteLinkedToStillSelectedInvoice (removedInvoiceId, creditNoteId) {
      const filteredSelectedDocuments = this.selectedDocuments.filter(({ id }) => id !== removedInvoiceId);
      return filteredSelectedDocuments.some((remainingSelectedDocument) => {
        return remainingSelectedDocument.linkedInvoices.some(({ id }) => id === creditNoteId);
      });
    },
    removeFromSelectedDocuments (documentId) {
      this.selectedDocuments.splice(
        this.selectedDocuments.findIndex(document => document.id === documentId), 1);
    },
    updateSelection ({ items, value }) {
      this.selectedDocuments = value ? items : [];
      this.$emit('changeSelectedDocuments', this.selectedDocuments);
    },
    async payInvoice (invoice) {
      const invoiceArray = new Array(invoice);
      const basket = basketService.createBasket({
        invoices: invoiceArray,
        accountId: this.selectedDebtorAccount.billingAccountId,
        creditorId: this.creditor.id
      });
      // store the basket
      await this.$store.dispatch('setBasket', basket);
      this.$router.push({ name: 'DebtorCheckout' });
    },
    resetSelectedDocuments () {
      this.selectedDocuments = [];
    }
  }
};
</script>