import Service from './Service';

class DebtorKpiService extends Service {
  getCreditorKpiAbstract (creditorIdList, debtorId) {
    const completeURL = 'invoiceapi/public/v2/kpi/creditor-abstract';
    return this.instance.get(completeURL, {
      params: {
        creditorIdList: creditorIdList.toString(),
        debtorId: debtorId
      }
    });
  }

  getCreditorKpiPieChartData (creditorIdList, debtorId) {
    const completeURL = 'invoiceapi/public/v2/kpi/creditor-pie-chart-data';
    return this.instance.get(completeURL, {
      params: {
        creditorIdList: creditorIdList.toString(),
        debtorId: debtorId
      }
    });
  }

  getKpiChartData (creditorIdList, debtorId) {
    const completeURL = 'invoiceapi/public/v2/kpi/chart-data';
    return this.instance.get(completeURL, {
      params: {
        creditorIdList: creditorIdList.toString(),
        debtorId: debtorId
      }
    });
  }

  getDebtorKpiAbstract (debtorId) {
    const completeURL = 'invoiceapi/public/v2/kpi/debtor-abstract';
    return this.instance.get(completeURL, {
      params: {
        debtorId: debtorId
      }
    });
  }
}

export default new DebtorKpiService();