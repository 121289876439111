<template>
  <div>
    <span>{{ dueDate | date }}</span>
    <span v-if="isBeforeToday(dueDate)">
      <br/>
      <v-icon color="red" small>mdi-clock-alert-outline</v-icon>
      (+{{ `${ daysSince(dueDate) }${ $t('DAY_FIRST_LETTER') }` }})
    </span>
  </div>
</template>

<script>
import dateMixin from '@/mixins/dateMixin';
import numberMixin from '@/mixins/numberMixin';

export default {
  mixins: [dateMixin, numberMixin],
  props: { dueDate: [String, Date] }
};
</script>
