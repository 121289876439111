<template>
  <v-card>
    <v-form
        ref="form"
        v-model="valid"
        class="pa-4 col-sm-6"
        @input="validateForm()"
    >
      <v-select
          v-model="user.gender"
          :items="titleItems"
          :rules="[rules.requiredFieldRules]"
          :label="$t('LABEL_TITLE')"
          required
      ></v-select>

      <v-text-field
          v-model="user.lastName"
          :rules="[rules.requiredFieldRules, rules.nameRules,  rules.nameRegex]"
          :label="$t('LABEL_LASTNAME')"
          counter="100"
          required
      ></v-text-field>

      <v-text-field
          v-model="user.firstName"
          :rules="[rules.requiredFieldRules, rules.nameRules, rules.nameRegex]"
          :label="$t('LABEL_FIRSTNAME')"
          counter="100"
          required
      ></v-text-field>

      <PhoneNumberInput :phone-number.sync="phoneNumber"
                        :phone-mask-key.sync="user.phoneMaskKey"
                        :is-valid.sync="phoneNumberValid"
                        :required="phoneNumberRequired"
                        :creditorId="creditorId">
      </PhoneNumberInput>

      <v-text-field
          v-model="user.email"
          :label="$t('LABEL_EMAIL')"
          disabled
      ></v-text-field>

      <v-row
        align="center"
        justify="space-around"
        class="pa-4">
        <div class="col-12 col-sm-4">{{ $t('LABEL_NOTIFICATION_MODE') }}</div>
        <!-- TODO: Waiting for https://jira.worldline.com/browse/MSPMWBPM-1093 to change disabled into -> :disabled="!updateMode" -->
        <v-radio-group
            v-model="channel"
            mandatory
            :disabled="!phoneNumberValid"
            row
            class="mx-sm-4">
          <v-radio
              v-for="item in notificationChannels"
              :key="item.key"
              :label="item.label"
              :value="item.key">
          </v-radio>
        </v-radio-group>
      </v-row>

      <v-row
        justify="space-around">
        <v-btn
            outlined
            @click="resetPassword"
            class="my-4"
        >
          {{ $t('BUTTON_MODIFY_PASSWORD') }}
        </v-btn>
        <v-btn
            :disabled="!valid"
            color="primary"
            @click="updateProfile"
            class="my-4"
        >
          {{ $t('BUTTON_MODIFY_INFORMATION') }}
        </v-btn>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import userMixin from '@/mixins/userMixin';
import userService from '@/services/userService';
import keycloakService from '@/services/keycloakService';
import maskMixin from '@/mixins/maskMixin';
import ruleMixin from '@/mixins/ruleMixin';
import { SECONDS_IN_A_DAY } from '@/mixins/tokenMixin';
import PhoneNumberInput from '@/components/common/PhoneNumberInput';

export default {
  components: { PhoneNumberInput },
  data () {
    return {
      valid: false,
      phoneNumber: undefined,
      channel: undefined,
      phoneNumberValid: undefined,
      defaultChannel: 'EMAIL',
      notificationChannels: [
        { key: 'EMAIL', label: this.$t('LABEL_EMAIL') },
        { key: 'SMS', label: this.$t('LABEL_TEXT_MESSAGE') }
      ],
      titleItems: [
        {
          value: 'MISS',
          text: this.$t('MISS')
        },
        {
          value: 'MS',
          text: this.$t('MS')
        },
        {
          value: 'MR',
          text: this.$t('MR')
        }
      ]
    };
  },
  beforeMount () {
    this.phoneNumber = this.user.phoneNumber ? this.user.phoneNumber : null;
    this.channel = this.user.channels[0] ? this.user.channels[0] : this.defaultChannel;
  },
  computed: {
    phoneNumberRequired () {
      return this.channel === 'SMS';
    },
    creditorId () {
      return this.isCreditor() ? this.$store.state.selectedCreditorAccount.id : this.$store.state.creditor.id;
    }
  },
  watch: {
    phoneNumberValid () {
      if (!this.phoneNumberValid) {
        this.channel = this.defaultChannel;
      }
    }
  },
  methods: {
    // Allow to display all form errors without clicking on every input
    validateForm () {
      this.$refs.form?.validate();
    },
    async updateProfile () {
      this.user.phoneNumber = this.phoneNumber ? this.replaceWithoutBlank(this.phoneNumber) : null;
      this.user.channels[0] = this.channel;
      try {
        if (this.isCreditor()) {
          await userService.updateCreditorUser(this.user);
        } else {
          await userService.updateDebtorUser(this.user);
        }
        // Force token refreshing to update form when you come back to this vue
        await keycloakService.keycloak.updateToken(SECONDS_IN_A_DAY);
        this.displaySuccessSnackbar(this.$t('SUCCESS_MESSAGE_UPDATED_USER'));
      } catch (err) {
        this.handleError(err);
      }
    },
    async resetPassword () {
      try {
        await userService.resetPassword(this.user.id);
        this.displaySuccessSnackbar(this.$t('SUCCESS_MESSAGE_RESET_PASSWORD'));
      } catch (err) {
        this.handleError(err);
      }
    }
  },
  mixins: [userMixin, maskMixin, ruleMixin]
};
</script>