<template>
  <v-row class="container">
    <v-col cols="12" class="text-center">
      <h1 class="mb-10 text-h4 pt-6">{{ $t('WELCOME_TITLE', { creditorName: creditor.name }) }}</h1>
      <h2 class="text-h5">{{ $t('WELCOME_SUBTITLE') }}</h2>
    </v-col>
    <v-col cols="12" sm="10" md="5" lg="4" offset-sm="1" offset-md="1" offset-lg="2">
      <v-card elevation="6" class="fill-height">
        <v-card-title class="prevent-break-word">{{ $t('NO_ACCOUNT_TITLE') }}</v-card-title>
        <v-card-text>{{ $t('NO_ACCOUNT_TEXT', { creditorName: creditor.name }) }}</v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" sm="10" md="5" lg="4" offset-sm="1" offset-md="0">
      <v-card
        elevation="6">
        <v-card-title class="prevent-break-word">{{ $t('ADD_ACCOUNT_TITLE') }}</v-card-title>
        <v-card-text>{{ $t('ADD_ACCOUNT_TEXT') }}</v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            class="mb-2 ml-2"
            :to="{ name: 'DebtorSettingsAccounts', query: { addAccount: true } }">
            {{ $t('ADD_ACCOUNT_LABEL') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['creditor'])
  }
};
</script>

<style scoped>
  .prevent-break-word {
    word-break: normal;
  }
</style>