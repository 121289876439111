<template>
  <v-card elevation="6">
    <v-card-text class="text-center px-0 font-weight-bold">
      <div v-if="loaded">
        <div class="kpi-number" v-bind:class="item.class">{{ item.count }}</div>
        <div>{{label}}</div>
        <div v-if="(item.label === 'INVOICES_TO_PAY' || item.label === 'INVOICES_TO_VALIDATE') && item.countCN">
          <i18n path="COUNT_CN">
            <template #countCN>
              <span :class="item.class">{{ item.countCN }}</span>
            </template>
          </i18n>
        </div>
        <v-divider class="mt-1 mb-3"></v-divider>
        <div>{{$t('TOTAL_AMOUNT')}} <span class="kpi-number" v-bind:class="item.class">{{ item.amount | currency(currency) }}</span></div>
      </div>
      <!-- LOADING COMPONENT -->
      <v-row v-else class="loading" align="center" justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import numberMixin from '@/mixins/numberMixin';

export default {
  mixins: [numberMixin],
  props: ['item', 'loaded', 'currency'],
  computed: {
    label () {
      // Utilisation du code ASCII dans les traduction, puis remplacement dans le label pour éviter
      // l'erreur eslint : invalid-first-character-of-tag-name
      return this.$t(this.item.label).replace('&lt;', '<');
    }
  }
};
</script>

<style scoped>
  .kpi-number {
    font-size: 1.4em;
  }

  .loading {
    min-height: 100px;
  }
</style>