import toNumber from 'lodash/toNumber';
import cloneDeep from 'lodash/cloneDeep';

import invoiceMapper from '@/mappers/invoiceMapper';

class BasketService {
  /**
   * Compute individual balance for each invoice
   * @param {Object} invoice - a copy of the invoice
   *
   * @returns {Object} balance consisting of amount
   */
  computeInvoiceBalance ({ temporaryOutstandingAmount }) {
    return {
      amount: temporaryOutstandingAmount
    };
  }

  /**
   * Format the given list of invoices to be compatible with the basket.
   * @param {Array} invoices - the invoices as provided by the invoiceMapper
   *
   * @returns {Array} the same list of invoices, sorted and each invoice has a balance
   */
  formatInvoices (invoices = []) {
    const formattedInvoices = cloneDeep(invoices || []);

    formattedInvoices.forEach(invoice => {
      invoice.balance = this.computeInvoiceBalance(invoice);
    });

    formattedInvoices.sort((a, b) => b.balance.amount - a.balance.amount);

    return formattedInvoices;
  }

  /**
   * Compute a balance object for a set of invoices
   * @param {Array} invoices - invoices to compute balance for
   *
   * @returns {Object} balance object
   */
  computeBasketBalance ({ invoices = [] }) {
    const balance = {
      invoices: {
        quantity: 0,
        amount: 0,
        totalWithTax: 0,
        totalWithoutTax: 0,
        totalOutstanding: 0
      },
      creditNotes: {
        quantity: 0,
        amount: 0,
        totalOutstanding: 0
      },
      amount: 0
    };
    invoices.forEach(invoice => {
      if (invoice.isInvoice) {
        balance.invoices.quantity++;
        balance.invoices.amount += toNumber(invoice.balance.amount);
        balance.invoices.totalWithTax += toNumber(invoice.amountWithTax);
        balance.invoices.totalWithoutTax += toNumber(invoice.amountExclusiveOfTax);
        balance.invoices.totalOutstanding += toNumber(invoice.temporaryOutstandingAmount);
      } else if (invoice.isCreditNote) {
        balance.creditNotes.quantity++;
        balance.creditNotes.amount += toNumber(invoice.balance.amount);
        balance.creditNotes.totalOutstanding += toNumber(invoice.temporaryOutstandingAmount);
      }
    });

    balance.amount = balance.invoices.amount - balance.creditNotes.amount;
    return balance;
  }

  /**
   * Create a basket with a set of invoices (may be empty)
   * @param {Object} params
   * @param {Array} params.invoices - invoices to add to the basket
   * @param {String} params.accountId - the debtor's accountId
   *
   * @returns {Object} the basket with the balance pre-computed
   */
  createBasket ({ invoices = [], accountId }) {
    const basket = {
      invoices: this.formatInvoices(invoices)
    };
    basket.balance = this.computeBasketBalance(basket);
    basket.accountId = accountId;
    return basket;
  }

  /**
   * Create a basket with a set of invoices from a transaction (special case)
   * @param {Object} transaction - the transaction as returned from the backend
   *
   * @returns {Object} the basket with the balance pre-computed
   */
  createBasketFromPaymentOperation ({ documents, debtor, creditor } = {}) {
    const invoices = documents.map(document => {
      return {
        ...invoiceMapper.mapOne(document.invoice),
        balance: {
          amount: document.amount
        }
      };
    });
    const basket = {
      invoices,
      creditorId: creditor.id
    };
    basket.balance = this.computeBasketBalance(basket);
    basket.accountId = `${creditor.accountId}_${debtor.accountId}`;
    return basket;
  }
}

export default new BasketService();
