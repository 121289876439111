var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[_c('ExportCsvButton',{attrs:{"disable":_vm.payments ? false : true,"loadingData":_vm.loading,"headers":_vm.headers.filter(function (header) { return header.exportable; }),"invoices":_vm.payments,"filename":_vm.$t('PAYMENTS')}}),_c('v-spacer'),_c('v-text-field',{staticClass:"search-bar",attrs:{"prepend-icon":"mdi-magnify","label":_vm.$t('SEARCH'),"single-line":"","hide-details":"","clearable":""},on:{"input":function($event){return _vm.getPayments()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.payments,"options":_vm.options,"footer-props":_vm.footerProps,"server-items-length":_vm.totalPayments,"sort-by":_vm.defaultSortBy,"sort-desc":"","loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"overline"},[_vm._v(_vm._s('P' + item.id.toString().padStart(6, '0')))])]}},{key:"item.creationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.creationDate))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.computePaymentStatusLabel(item.status))+" ")]}},{key:"item.paymentMeanType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.paymentMeanI18nTag))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('strong',{staticClass:"black--text"},[_vm._v(_vm._s(_vm._f("currency")(item.amount,item.currency)))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","to":{ name: 'DebtorPaymentDetail', params: { transactionReference: item.transactionReference }}}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON_DETAILS'))+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }