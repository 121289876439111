<template>
  <v-dialog v-model="revokeDialog" persistent max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-on="on"
          v-bind="attrs"
          class="ma-2 button-minified"
          color="red"
          dark
          small
          :disabled="!(isDebtorAdmin && statusDeleteValid)"
          >
        <v-icon dark small>
          mdi-trash-can-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline text-center">
        <span class="force-break-word">{{ $t('CONFIRMATION_REVOKE', { umr: mandate.umr, iban: mandate.iban }) }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-space-around">
        <v-btn color="error" dark @click.stop="revokeDialog = false">
          {{ $t('ACTION_CANCEL') }}
        </v-btn>
        <v-btn color="primary"
          dark
          @click="deleteMandate(mandate.umr)"
          :loading="revokeLoading"
        >
          {{ $t('ACTION_VALIDATE') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import sepaMandateService from '@/services/sepaMandateService';
import userMixin from '@/mixins/userMixin';

export default {
  mixins: [userMixin],
  props: {
    mandate: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      revokeDialog: false,
      revokeLoading: false
    };
  },
  computed: {
    statusDeleteValid () {
      const STATUS_DELETE_VALID = [
        'ACTIVE',
        'PENDING',
        'SENT_TO_DEBTOR',
        'SUSPENDED',
        'WAITING',
        'WAITING_REACHABILITY',
        'WAITING_FOR_VALIDATION'
      ];
      return STATUS_DELETE_VALID.indexOf(this.mandate.status) !== -1;
    }
  },
  methods: {
    async deleteMandate (umr) {
      this.revokeLoading = true;
      try {
        await sepaMandateService.revokeMandate(this.$route.params.accountId, umr);
        this.displaySuccessSnackbar(this.$t('SUCCESS_REVOKE'));
        this.$emit('refresh-table');
      } catch (err) {
        this.handleError(err);
      }
      this.revokeDialog = false;
      this.revokeLoading = false;
    }
  }
};
</script>