<template>
  <v-container v-if="creditor && creditor.creditorSettings.VALIDATION_ENABLED.value">
    <v-row>
      <v-col cols="12">
        <SelectableInvoicesTable
            :privateEventBus="privateEventBus"
            :loading="loading"
            :invoices="invoices"
            :selectedDebtorAccount="selectedDebtorAccount"
            @changeSelectedDocuments="selectedDocuments = $event"
            toValidatePage>
        </SelectableInvoicesTable>
      </v-col>
    </v-row>
    <BottomBar
        :privateEventBus="privateEventBus"
        :selectedDocuments="selectedDocuments"
        :selectedDebtorAccount="selectedDebtorAccount"
        :isDocumentSelection="isDocumentSelection"
        toValidatePage>
    </BottomBar>
  </v-container>
</template>

<script>
import invoiceService from '@/services/invoiceService';
import invoiceMapper from '@/mappers/invoiceMapper';
import invoiceMixin from '@/mixins/invoiceMixin';

export default {
  mixins: [invoiceMixin],
  async beforeMount () {
    if (!this.creditor?.creditorSettings.VALIDATION_ENABLED.value) {
      await this.$router.push({ name: 'Error' });
    }
  },
  methods: {
    async getInvoices (debtorBillingAccountId) {
      this.loading = true;
      try {
        const { data } = await invoiceService.getInvoicesToValidate(debtorBillingAccountId);
        this.invoices = invoiceMapper.mapAll(data.content);
        this.selectedDebtorAccount.toValidateInvoicesCount = data.totalElements;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
