<script>
import { Bar } from 'vue-chartjs';
import dateService from '@/services/dateService';
import merge from 'lodash/merge';

export default {
  extends: Bar,
  props: ['kpiData', 'scale', 'currencySymbol'],
  data: () => ({
    diagramFormattedData: {},
    kpiLabels: {},
    chartData: {
      datasets: [
        { backgroundColor: '#2E7D32', hoverBackgroundColor: '#2E7D32', barPercentage: 0.6 },
        { backgroundColor: '#A5D6A7', hoverBackgroundColor: '#A5D6A7', barPercentage: 0.6 },
        { backgroundColor: '#C7C7C7', hoverBackgroundColor: '#C7C7C7', barPercentage: 0.6 }
      ]
    },
    options: {
      curvature: 1,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{ scaleLabel: { display: true }, stacked: true, gridLines: { color: '#FFFFFF' } }],
        yAxes: [{ scaleLabel: { display: true }, stacked: true, ticks: { maxTicksLimit: 5, beginAtZero: true } }]
      },
      plugins: {
        labels: false
      }
    }
  }),
  methods: {
    setDataAndLabels () {
      if (this.scale === 'MONTHS') {
        this.chartData.datasets = merge(this.chartData.datasets, this.diagramFormattedData.amountsGroupByMonths);
        this.chartData.labels = this.kpiLabels.monthsLabels;
      } else if (this.scale === 'WEEKS') {
        this.chartData.datasets = merge(this.chartData.datasets, this.diagramFormattedData.amountsGroupByWeeks);
        this.chartData.labels = this.kpiLabels.weeksLabels;
      }
    },

    formatData (data) {
      return { data };
    }
  },
  watch: {
    scale: function () {
      this.setDataAndLabels();
      this.renderChart(this.chartData, this.options);
    }
  },
  async beforeMount () {
    this.options.scales.xAxes[0].scaleLabel.labelString = this.$t('INVOICE_DUE_DATE');
    this.options.scales.yAxes[0].scaleLabel.labelString = this.$t('INVOICES_CUMULATION') + ' (' + this.currencySymbol + ')';

    this.chartData.datasets[0].label = this.$t('STATUS_SETTLED');
    this.chartData.datasets[1].label = this.$t('PAID');
    this.chartData.datasets[2].label = this.$t('TO_PAY');

    this.kpiLabels = {
      monthsLabels: dateService.getLastMonthsShortNames(7, this.$i18n.locale),
      weeksLabels: dateService.getLastWeeksFirstAndLastDay(7, this.$i18n.locale)
    };

    this.diagramFormattedData = {
      amountsGroupByWeeks: await this.kpiData.amountsGroupByWeeks?.map(this.formatData),
      amountsGroupByMonths: await this.kpiData.amountsGroupByMonths?.map(this.formatData)
    };

    this.setDataAndLabels();

    await this.renderChart(this.chartData, this.options);
  }
};
</script>