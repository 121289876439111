var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('ExportCsvButton',{attrs:{"loadingData":_vm.loading,"headers":_vm.exportableHeaders,"invoices":_vm.invoices,"filename":_vm.$t('NAME_CSV_FILE_PAID')}}),_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":_vm.$t('SEARCH'),"single-line":"","hide-details":"","clearable":""},on:{"input":function($event){return _vm.getInvoices(_vm.debtorAccountId)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.invoices,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalInvoices,"footer-props":_vm.footerProps,"sort-by":_vm.defaultSortBy},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.defaultSortBy=$event},"update:sort-by":function($event){_vm.defaultSortBy=$event}},scopedSlots:_vm._u([{key:"item.refDoc",fn:function(ref){
var item = ref.item;
return [_c('InvoiceNumberWithDownloadLink',{attrs:{"invoice":item}})]}},{key:"item.externalRef",fn:function(ref){
var item = ref.item;
return [(item.linkedInvoices && item.linkedInvoices.length)?_c('span',[_c('v-list',_vm._l((item.linkedInvoices),function(linkedInvoice){return _c('v-list-item',{key:linkedInvoice.id,staticClass:"pa-0"},[_c('router-link',{attrs:{"to":{
                  name: _vm.routeDetail,
                  params: { id: linkedInvoice.id }
                }}},[_vm._v(" "+_vm._s(_vm.$t('INVOICE'))+" "+_vm._s(linkedInvoice.number)+" ")])],1)}),1)],1):(item.externalRef)?_c('span',[_vm._v(_vm._s(_vm.$t('ORDER'))+" "+_vm._s(item.externalRef))]):_vm._e()]}},{key:"item.issueDate",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.issueDate)))])]}},{key:"item.amountExclusiveOfTax",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.amountExclusiveOfTax,item.currency)))])]}},{key:"item.amountWithTax",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.amountWithTax,item.currency)))])]}},{key:"item.dueDate",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("date")(item.dueDate)))])]}},{key:"item.paymentDate",fn:function(ref){
                var item = ref.item;
return [(item.isPaymentValidated)?_c('span',[(item.isPaymentAccepted)?_c('v-icon',{attrs:{"color":"blue","small":""}},[_vm._v("mdi-clock-alert-outline")]):_vm._e(),_vm._v(_vm._s(_vm._f("date")(item.paymentDate))+" ")],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","to":{
            name: _vm.routeDetail,
            params: { id: item.id } }}},[_vm._v(" "+_vm._s(_vm.$t('BUTTON_DETAILS'))+" ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }