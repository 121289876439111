<template>
  <v-row>
    <v-col
        cols="12"
        md="5"
        class="no-bottom-padding-12">
      <v-skeleton-loader
        class="mx-auto"
        type="card-avatar, article@2, paragraph, actions"
        v-if="debtorAccountLoading"
      ></v-skeleton-loader>
      <v-card
        v-else
      >
        <v-card-title class="text-h6 force-break-word">
          {{ $t('DEBTOR_ACCOUNT_DETAILS_TITLE') }} {{ debtorAccount.code }}
        </v-card-title>
        <v-form
            ref="form"
            v-model="valid"
            class="pa-4"
            :disabled="!editionEnabled"
        >
          <v-text-field
              v-model="debtorAccount.name"
              :label="$t('LABEL_ACCOUNT_NAME')"
              filled
              readonly
              class="readonly-no-border"
          ></v-text-field>

          <v-container>
            <v-card-title class="pa-0 pb-2">{{ $t('LABEL_CONTACT_PERSON') }}</v-card-title>
            <v-text-field
                v-model="debtorAccount.contact.lastName"
                :rules="[rules.requiredFieldRules, rules.nameRules]"
                :label="$t('LABEL_LASTNAME')"
                counter="20"
                required
            ></v-text-field>

            <v-text-field
                v-model="debtorAccount.contact.firstName"
                :rules="[rules.requiredFieldRules, rules.nameRules]"
                :label="$t('LABEL_FIRSTNAME')"
                counter="20"
                required
            ></v-text-field>

            <PhoneNumberInput :phone-number.sync="debtorAccount.contact.phone"
            :creditorId="creditorId"></PhoneNumberInput>

            <v-text-field
                v-model="debtorAccount.contact.emailAddress"
                :rules="[rules.emailRules]"
                :label="$t('LABEL_EMAIL')"
                required
            ></v-text-field>
          </v-container>
          <v-row v-if="creditor && creditor.creditorSettings.VALIDATION_ENABLED.value"
              align="center"
              class="pa-4">
            <div class="col-12 col-sm-4 pa-0">{{ $t('LABEL_ACCOUNT_INVOICE_VALIDATION_REQUIRED') }}</div>
            <v-radio-group
                v-model="debtorAccount.debtorSettings.VALIDATION_REQUIRED.value"
                row
                class="mx-sm-4">
              <v-radio :label="$t('LABEL_YES')" :value="true"></v-radio>
              <v-radio :label="$t('LABEL_NO')" :value="false"></v-radio>
            </v-radio-group>
          </v-row>

          <v-row v-if="editionEnabled"
              justify="space-around">
            <v-btn
                class="my-4"
                @click="cancelDebtorUpdate()"
                :disabled="loadingSave"
            >
              {{ $t('ACTION_CANCEL') }}
            </v-btn>
            <v-btn
                :disabled="!valid"
                :loading="loadingSave"
                color="primary"
                @click="validateDebtorAccountInfo()"
                class="my-4 px-4"
            >
              {{ $t('ACTION_VALIDATE') }}
            </v-btn>
          </v-row>
          <v-row v-else
                 justify="space-around">
            <v-btn color="primary"
                   class="mb-2"
                   :disabled="!isDebtorAdmin"
                   @click="enableEdition()">
              {{ $t('BUTTON_MODIFY_INFORMATION') }}
            </v-btn>
          </v-row>
        </v-form>
      </v-card>
    </v-col>
    <v-col
        cols="12"
        md="7"
        class="no-bottom-padding-12">
      <v-card v-if="isDebtorAdmin" class="mb-4">
        <v-card-title
            class="text-subtitle-1 force-break-word">
          <div class="col-6">{{ $t('TITLE_DEBTOR_USERS') }}</div>
          <v-spacer/>
          <v-dialog v-model="searchEmailDialog" persistent max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-on="on"
                  v-bind="attrs"
                  color="primary"
                  class="col-5">
                <v-icon dark class="mr-2">
                  mdi-account-plus
                </v-icon>
                {{ $t('BUTTON_NEW_USER') }}
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline text-center">
                <span class="force-break-word">{{ $t('SEARCH_EMAIL') }}</span>
              </v-card-title>
              <v-card-text>
                <v-form
                  v-model="searchEmailValid"
                >
                  <v-text-field
                    v-model="searchEmail"
                    :rules="[rules.emailRules]"
                    :label="$t('SEARCH_EMAIL_PLACEHOLDER')"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="d-flex justify-space-around">
                <v-btn
                  color="error"
                  @click="searchEmailDialog = false">
                  {{ $t('ACTION_CANCEL') }}
                </v-btn>
                <v-btn
                  color="primary"
                  :loading="searchLoading"
                  :disabled="!searchEmailValid"
                  @click="searchEmailConfirm(searchEmail)">
                  {{ $t('ACTION_SEARCH') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-title>
        <v-container>
          <v-data-table
              :headers="tableHeaders"
              :items="debtorAccountMembers"
              :loading="loading"
          >
            <template v-slot:item.mainRole="{ value }">
              <td>{{ computeDebtorUserRole(value) }}</td>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-center">
                <DebtorSettingsAccountAddOrEditUser
                  action="edit"
                  @refresh-table="getDebtorAccountMembers"
                  :userProp="item"
                  :debtorId="debtorAccount.id"
                ></DebtorSettingsAccountAddOrEditUser>
                <DebtorSettingsAccountDissociateUser
                  @refresh-table="getDebtorAccountMembers"
                  :userProp="item"
                  :debtorId="debtorAccount.id"
                ></DebtorSettingsAccountDissociateUser>
              </div>
            </template>
          </v-data-table>
        </v-container>
        <DebtorSettingsAccountAddOrEditUser
          action="add"
          v-if="debtorUser"
          @refresh-table="getDebtorAccountMembers"
          :userProp="debtorUser"
          :debtorId="debtorAccount.id"
          ref="addOrEditUser"
        ></DebtorSettingsAccountAddOrEditUser>
      </v-card>
      <v-card v-if="isSepaDirectDebitEnabled">
        <v-card-title
            class="text-subtitle-1 force-break-word">
          <div class="col-6">{{ $t('TITLE_ACCOUNTS_SEPA') }}</div>
          <v-spacer/>
          <v-btn
              @click="createMandate"
              color="primary"
              class="col-5"
              :disabled="!isDebtorAdmin">
            <v-icon dark class="mr-2">
              mdi-receipt
            </v-icon>
            {{ $t('BUTTON_NEW_MANDATE') }}
          </v-btn>
        </v-card-title>
        <v-container>
          <v-data-table
            :headers="tableMandatesHeaders"
            :items="mandates"
            :loading="loadingSepa"
          >
            <template v-slot:item.signatureDate="{ item }">
              <span>{{ item.signatureDate | date }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-center">
                <v-btn
                  class="ma-2 button-minified"
                  color="primary"
                  dark
                  small
                  :disabled="!(isDebtorAdmin && item.status === 'ACTIVE')"
                  @click="updateIbanMandate(item.umr)"
                >
                  <v-icon dark small :aria-label="$t('BUTTON_UPDATE_IBAN')">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <DebtorSettingsAccountRevokeMandat
                  @refresh-table="getMandates($route.params.accountId)"
                  :mandate="item"
                ></DebtorSettingsAccountRevokeMandat>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import userService from '@/services/userService';
import paymentMeanService from '@/services/paymentMeanService';
import sepaMandateService from '@/services/sepaMandateService';
import DebtorSettingsAccountAddOrEditUser from '@/views/debtor/settings/DebtorSettingsAccountAddOrEditUser.vue';
import DebtorSettingsAccountDissociateUser from '@/views/debtor/settings/DebtorSettingsAccountDissociateUser.vue';
import DebtorSettingsAccountRevokeMandat from '@/views/debtor/settings/DebtorSettingsAccountRevokeMandat.vue';
import userMixin from '@/mixins/userMixin';
import maskMixin from '@/mixins/maskMixin';
import ruleMixin from '@/mixins/ruleMixin';
import dateMixin from '@/mixins/dateMixin';
import tokenMixin from '@/mixins/tokenMixin';
import PhoneNumberInput from '@/components/common/PhoneNumberInput';

export default {
  mixins: [userMixin, maskMixin, ruleMixin, dateMixin, tokenMixin],
  components: {
    DebtorSettingsAccountAddOrEditUser,
    DebtorSettingsAccountDissociateUser,
    PhoneNumberInput,
    DebtorSettingsAccountRevokeMandat
  },
  data () {
    return {
      editionEnabled: false,
      searchEmailDialog: false,
      searchEmail: '',
      debtorAccount: {
        contact: {}
      },
      debtorAccountBeforeChanges: null,
      loading: false,
      loadingSave: false,
      searchLoading: false,
      debtorAccountLoading: true,
      debtorAccountMembers: [],
      valid: false,
      searchEmailValid: false,
      debtorUser: {},
      tableHeaders: [
        {
          text: this.$t('LABEL_EMAIL'),
          value: 'email'
        },
        {
          text: this.$t('LABEL_FIRSTNAME'),
          value: 'firstName'
        },
        {
          text: this.$t('LABEL_LASTNAME'),
          value: 'lastName'
        },
        {
          text: this.$t('LABEL_PROFILE'),
          value: 'mainRole'
        },
        {
          text: this.$t('LABEL_ACTIONS'),
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      isSepaDirectDebitEnabled: false,
      loadingSepa: false,
      tableMandatesHeaders: [
        {
          text: this.$t('UMR_MANDATE_SEPA'),
          value: 'umr'
        },
        {
          text: this.$t('SIGNATURE_DATE_MANDATE_SEPA'),
          value: 'signatureDate'
        },
        {
          text: this.$t('STATUS'),
          value: 'status'
        },
        {
          text: this.$t('BIC_MANDATE_SEPA'),
          value: 'bic'
        },
        {
          text: this.$t('IBAN_MANDATE_SEPA'),
          value: 'iban'
        },
        {
          text: this.$t('LABEL_ACTIONS'),
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      mandates: [],
      mandateActionMsgs: {
        sepaCreate_OK: this.$t('SUCCESS_MESSAGE_ADD_SEPA_MANDATE'),
        ibanSepaUpdate_OK: this.$t('SUCCESS_MESSAGE_UPDATE_IBAN_SEPA_MANDATE'),
        sepaCreate_cancel: this.$t('CANCEL_MESSAGE_ADD_SEPA_MANDATE'),
        ibanSepaUpdate_cancel: this.$t('CANCEL_MESSAGE_UPDATE_IBAN_SEPA_MANDATE'),
        sepaCreate_KO: this.$t('ERROR_MESSAGE_ADD_SEPA_MANDATE'),
        ibanSepaUpdate_KO: this.$t('ERROR_MESSAGE_UPDATE_IBAN_SEPA_MANDATE')

      }
    };
  },
  async beforeMount () {
    // Treatment return of SPS if create or update Mandate Sepa is called
    if (this.isSepaCreateMode || this.isSepaUpdateMode) {
      this.checkSpsStatusReturn(this.$route.params.action, this.$route.query.status);
    }
    const debtorAccountId = this.$route.params.accountId;
    await this.getAccountInfo(debtorAccountId);
    this.getDebtorAccountMembers();
    this.getSepaAvailable();
  },
  computed: {
    isSepaCreateMode () {
      return this.$route.params.action === 'sepaCreate';
    },
    isSepaUpdateMode () {
      return this.$route.params.action === 'ibanSepaUpdate';
    },
    creditorId () {
      return this.isCreditor() ? this.$store.state.selectedCreditorAccount.id : this.$store.state.creditor.id;
    }
  },
  methods: {
    enableEdition () {
      // Allow to display all form errors without clicking on every input
      this.$refs.form?.validate();
      this.editionEnabled = true;
    },
    async getAccountInfo (accountId) {
      try {
        const { data } = await userService.getDebtorAccount(accountId);
        // To prevent undefined error when display contact values
        if (!data?.contact) {
          data.contact = {};
        }
        this.debtorAccount = data;
        // Need to use JSON.parse(JSON.stringify(...)) to create a deep copy to keep original debtor account info
        this.debtorAccountBeforeChanges = JSON.parse(JSON.stringify(data));
        this.debtorAccountLoading = false;
      } catch (err) {
        this.handleError(err);
      }
    },
    async getDebtorAccountMembers () {
      try {
        this.loading = true;
        if (this.isDebtorAdmin) {
          const { data } = await userService.getDebtorAccountMembers(this.debtorAccount.id);
          this.debtorAccountMembers = data;
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
    cancelDebtorUpdate () {
      this.editionEnabled = false;
      // Need to use JSON.parse(JSON.stringify(...)) to create a deep copy to keep original debtor account info
      this.debtorAccount = JSON.parse(JSON.stringify(this.debtorAccountBeforeChanges));
    },
    async validateDebtorAccountInfo () {
      this.debtorAccount.contact.phone = this.replaceWithoutBlank(this.debtorAccount.contact.phone);

      if (this.$refs.form.validate()) {
        try {
          this.loadingSave = true;
          await userService.updateBillingAccount(this.debtorAccount);
          this.loadingSave = false;
          this.editionEnabled = false;
          this.displaySuccessSnackbar(this.$t('SUCCESS_MESSAGE_UPDATE_DEBTOR_ACCOUNT'));
          await this.refreshAppContext();
        } catch (err) {
          this.handleError(err);
        } finally {
          this.loadingSave = false;
        }
      }
    },
    async searchEmailConfirm (email) {
      this.searchLoading = true;
      try {
        const { data } = await userService.verifyUniqueLogin(email, this.debtorAccount.id);
        if (data.unKnownUser) {
          this.debtorUser = { email, keycloakRoles: [] };
          this.openAddUserDialog();
        } else if (!data.userLinkedToDebtorAccount) {
          this.debtorUser = data.user;
          // Remove undesired roles from back
          this.debtorUser.mainRole = [];
          this.openAddUserDialog();
        } else {
          this.displayErrorSnackbar(this.$t('ERROR_MESSAGE_SEARCH_EMAIL'));
        }
        this.searchEmailDialog = false;
      } catch (err) {
        this.handleError(err);
      }
      this.searchLoading = false;
      this.searchEmail = '';
    },
    openAddUserDialog () {
      this.$refs.addOrEditUser.openDialog();
    },
    async getSepaAvailable () {
      try {
        const { data } = await paymentMeanService.getEnabledPaymentMeans(this.creditor.id);
        this.isSepaDirectDebitEnabled = data?.some(paymentMean => paymentMean.type === 'SEPA_DD');

        if (this.isSepaDirectDebitEnabled && this.isDebtorAdmin) {
          this.getMandates(this.$route.params.accountId);
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    async getMandates (debtorAccountId) {
      try {
        this.loadingSepa = true;
        const { data } = await sepaMandateService.getMandatesByBillingAccountId(debtorAccountId);
        this.mandates = data;
      } catch (err) {
        this.displayErrorSnackbar(this.$t('PAYMENT_SEPA_UNAVAILABLE'));
      } finally {
        this.loadingSepa = false;
      }
    },
    async createMandate () {
      try {
        const { data } = await sepaMandateService.createMandate(this.$route.params.accountId);
        if (data.redirectionUrl) {
          window.location.href = data.redirectionUrl;
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    checkSpsStatusReturn (sepaAction, statusParam) {
      const prop = sepaAction + '_' + statusParam;
      if (statusParam === 'OK') {
        this.displaySuccessSnackbar(this.mandateActionMsgs[prop]);
      } else if (statusParam === 'cancel') {
        this.displayWarningSnackbar(this.mandateActionMsgs[prop]);
      } else if (statusParam === 'KO') {
        this.displayErrorSnackbar(this.mandateActionMsgs[prop]);
      }
    },
    async updateIbanMandate (umr) {
      try {
        const { data } = await sepaMandateService.updateIbanMandate(this.$route.params.accountId, umr);
        if (data.redirectionUrl) {
          window.location.href = data.redirectionUrl;
        }
      } catch (err) {
        this.handleError(err);
      }
    }
  }
};
</script>

<style scoped>
  .no-bottom-padding-12 {
    padding: 12px 12px 0 12px !important;
  }
  /*
  /deep/ allows to override built-in vuetify css classes, such as ".v-input__slot" below
  In this case, we want to remove the bottom-border built in with v-text-field
   */
  /deep/ .readonly-no-border > .v-input__control > .v-input__slot:before, /deep/ .readonly-no-border > .v-input__control > .v-input__slot:after {
    border-style: none !important;
  }
</style>