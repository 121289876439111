<template>
  <v-container>
    <v-row v-if="isDebtorAccountsListEmpty">
      <NewUserGuide></NewUserGuide>
    </v-row>
    <div v-else>
      <!-- INDICATORS COMPONENTS -->
      <v-row justify="center">
        <v-col cols="12" sm="6" md="4" lg="2"
               v-for="item in kpiIndicatorsData" v-bind:key="item.label">
          <KpiIndicator v-bind:item="item"
              v-bind:loaded="kpiIndicatorsDataLoaded"
              v-bind:currency="selectedDebtorAccount.currency">
          </KpiIndicator>
        </v-col>
      </v-row>
      <!-- CHART COMPONENT -->
      <v-row class="mt-1" justify="center">
        <v-col cols="12" lg="6">
          <v-card elevation="6">
            <v-card-title class="headline justify-center">
              {{ $t('PENDING_INVOICES_GRAPHIC_TITLE', { currency: debtorCurrencySymbol }) }}
            </v-card-title>
            <v-card-text class="border-top pt-1">
              <v-row justify="center">
                <v-radio-group v-model="kpiChartSelectedOption"
                               :disabled="!kpiChartDataLoaded"
                               row>
                  <template v-slot:label>
                    <div>{{ $t('DISPLAY_BY') }}</div>
                  </template>
                  <v-radio v-for="option in kpiChartOptions"
                           v-bind:key="option"
                           :label="$t(option)"
                           :value="option">
                  </v-radio>
                </v-radio-group>
              </v-row>
            </v-card-text>
            <KpiChart class="pr-6"
                              v-if="kpiChartDataLoaded"
                              v-bind:kpi-data="kpiChartData"
                              v-bind:scale="kpiChartSelectedOption"
                              v-bind:currency-symbol="debtorCurrencySymbol">
          </KpiChart>
            <!-- CHART LOADING COMPONENT -->
            <v-row v-else class="chart-loading" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary">
              </v-progress-circular>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import kpiService from '@/services/kpiService';
import merge from 'lodash/merge';
import numberMixin from '@/mixins/numberMixin';
import KpiChart from '@/components/common/kpi/KpiChart';
import KpiIndicator from '@/components/common/kpi/KpiIndicator';
import NewUserGuide from '@/components/debtor/NewUserGuide';

export default {
  name: 'DebtorDashboard',
  mixins: [numberMixin],
  components: { KpiIndicator, KpiChart, NewUserGuide },
  computed: {
    ...mapState(['selectedDebtorAccount', 'debtorAccounts', 'creditor']),
    isDebtorAccountsListEmpty () {
      return !this.debtorAccounts?.length;
    },
    debtorCurrencySymbol () {
      return this.getCurrencySymbol(this.selectedDebtorAccount.currency);
    }
  },
  data: () => ({
    currentUsedAccountId: null,
    kpiChartDataLoaded: false,
    kpiIndicatorsDataLoaded: false,
    kpiChartData: {},
    kpiIndicatorsData: {
      invoicesToValidate: { label: 'INVOICES_TO_VALIDATE', class: 'blue--text' },
      invoicesToPay: { label: 'INVOICES_TO_PAY', class: 'blue--text' },
      invoicesToPayWithinMoreThan7Days: { label: 'INVOICES_TO_PAY_WITHIN_MORE_THAN_7_DAYS', class: 'green--text' },
      invoicesToPayWithin7Days: { label: 'INVOICES_TO_PAY_WITHIN_7_DAYS', class: 'orange--text' },
      lateInvoices: { label: 'LATE_INVOICES', class: 'red--text' }
    },
    kpiChartOptions: ['MONTHS', 'WEEKS'],
    kpiChartSelectedOption: 'MONTHS',
    creditorValidationEnabled: false
  }),
  beforeMount () {
    this.creditorValidationEnabled = this.creditor?.creditorSettings?.VALIDATION_ENABLED?.value;
  },
  methods: {
    async loadKpiChartData () {
      this.kpiChartDataLoaded = false;
      try {
        const { data } = await kpiService.getKpiChartData([this.creditor.id], this.selectedDebtorAccount.id);
        this.kpiChartData = data;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.kpiChartDataLoaded = true;
      }
    },
    async loadKpiIndicatorsData () {
      this.kpiIndicatorsDataLoaded = false;
      try {
        const { data } = await kpiService.getDebtorKpiAbstract(this.selectedDebtorAccount.id);
        this.kpiIndicatorsData = merge(this.kpiIndicatorsData, data);
        if (!this.creditorValidationEnabled) {
          delete this.kpiIndicatorsData.invoicesToValidate;
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.kpiIndicatorsDataLoaded = true;
      }
    },
    getAllData () {
      if (this.selectedDebtorAccount && this.selectedDebtorAccount.accountId !== this.currentUsedAccountId) {
        this.currentUsedAccountId = this.selectedDebtorAccount.accountId;
        this.loadKpiChartData();
        this.loadKpiIndicatorsData();
      }
    }
  },
  created () {
    this.getAllData();
  },
  watch: {
    selectedDebtorAccount: function () {
      this.getAllData();
    }
  }
};
</script>

<style scoped>
  .chart-loading {
    min-height: 150px;
  }

  .border-top {
    border-top: 1px #E5E5E5 solid;
  }
</style>