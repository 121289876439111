import Service from './Service';
import { RedirectUrlEnum } from '../enums/redirectUrlEnum';

class SepaMandateService extends Service {
  getMandatesByBillingAccountId (billingAccountId) {
    return this.instance.get(`invoiceapi/v1/debtors/${billingAccountId}/mandates`);
  }

  createMandate (billingAccountId, redirectUrl = RedirectUrlEnum.DEBTOR_ACCOUNT_DETAILS) {
    return this.instance.post(`invoiceapi/v1/debtors/${billingAccountId}/mandates`, null, {
      params: {
        redirectUrl: redirectUrl
      }
    });
  }

  updateIbanMandate (billingAccountId, umr) {
    return this.instance.put(`invoiceapi/v1/debtors/${billingAccountId}/mandates/${umr}`);
  }

  revokeMandate (billingAccountId, umr) {
    return this.instance.delete(`invoiceapi/v1/debtors/${billingAccountId}/mandates/${umr}`);
  }
}

export default new SepaMandateService();
