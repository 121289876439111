import moment from 'moment-timezone';

class DateService {
  /**
   * Returns an array of previous months with the format MMM-YY.
   *
   * @param numberOfMonths Requested number of months
   * @param locale         Used to define labels languages
   */
  getLastMonthsShortNames (numberOfMonths, locale) {
    // Set the language for months names
    moment.locale(locale);
    const requestedMonths = [];
    const date = moment().add(4, 'month');

    for (let i = 0; i < numberOfMonths; i++) {
      requestedMonths.unshift(date.format('MMM-YY'));
      date.subtract(1, 'month');
    }

    return requestedMonths;
  }

  /**
   * Returns an array of previous weeks with the format D/MM-D/MM (begin-end).
   *
   * @param numberOfWeeks Requested number of weeks
   * @param locale locale used for date format
   */
  getLastWeeksFirstAndLastDay (numberOfWeeks, locale) {
    // Set the language for weeks names
    moment.locale(locale);
    const requestedWeeks = [];
    const date = moment().add(4, 'week');

    for (let i = 0; i < numberOfWeeks; i++) {
      requestedWeeks.unshift(date.startOf('isoWeek').format('D/MM') + '-' + date.endOf('isoWeek').format('D/MM'));
      date.subtract(1, 'week');
    }

    return requestedWeeks;
  }
}

export default new DateService();
