export default {
  directives: {
    mask: {
      update: function (el, binding) {
        if (el.getElementsByTagName('input').length === 1) {
          el = el.getElementsByTagName('input')[0];
          let i = 0;
          const phoneString = el.value.replace(/\s/g, '').toString();
          let reducedPattern;
          if (phoneString.length % 2 === 0) {
            reducedPattern = binding.value.substring(0, (phoneString.length + (phoneString.length) / 2) - 1);
          } else {
            reducedPattern = binding.value.substring(0, (phoneString.length + (phoneString.length) / 2));
          }
          const newValue = reducedPattern.replace(/#/g, _ => phoneString[i++]);
          if (newValue !== el.value) {
            el.value = newValue;
            el.dispatchEvent(new Event('input'));
          }
        }
      }
    }
  },
  methods: {
    replaceWithoutBlank (value) {
      if (value) {
        return value.replace(/\s/g, '');
      }
    }
  }
};