import keycloakService from '@/services/keycloakService';
import contextService from '@/services/contextService';

export const SECONDS_IN_A_DAY = 86400;

export default {
  methods: {
    async refreshAppContext () {
      await keycloakService.keycloak.updateToken(SECONDS_IN_A_DAY);
      const { data } = await contextService.getContext();
      this.$store.dispatch('setDebtorContext', { creditor: data.creditor, debtors: data.debtors });
    }
  }
};