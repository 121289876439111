<template>
  <v-col class="text-left">
      <v-btn :loading="loadingData" :disabled="disable" color="primary" @click="exportData">
        <v-icon left> mdi-microsoft-excel </v-icon>
        {{ $t('EXPORT_CSV') }}
      </v-btn>
    </v-col>
</template>

<script>

export default {
  props: {
    headers: {
      type: Array,
      required: true
    },
    invoices: {
      type: Array,
      required: false,
      default: () => []
    },
    filename: {
      type: String,
      required: true
    },
    loadingData: {
      type: Boolean,
      required: true
    },
    disable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    exportData () {
      const rows = [
        [...this.headers.map(e => e.text)]
      ];
      this.invoices.forEach(invoice => {
        const row = [];
        this.headers.forEach(header => {
          row.push(invoice[header.value]);
        });
        rows.push(row);
      });
      const csvContent = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(rows.map(e => e.join(';')).join('\r\n'));
      const downloadLink = document.createElement('a');
      downloadLink.href = csvContent;
      downloadLink.download = this.filename + '.csv';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }
};
</script>