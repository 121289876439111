var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-end"},[_c('v-dialog',{attrs:{"max-width":"500px","persistent":"","no-click-animation":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t('BUTTON_ADD_ACCOUNT'))+" ")],1)]}}]),model:{value:(_vm.dialogAddAccount),callback:function ($$v) {_vm.dialogAddAccount=$$v},expression:"dialogAddAccount"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline text-center"},[_c('span',{staticClass:"force-break-word"},[_vm._v(_vm._s(_vm.$t('TITLE_ADD_DEBTOR_ACCOUNT')))])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('LABEL_DEBTOR_ACCOUNT_ID'),"required":"","readonly":_vm.dialogAddAccountLoading},model:{value:(_vm.debtorAccountId),callback:function ($$v) {_vm.debtorAccountId=$$v},expression:"debtorAccountId"}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('TOOLTIP_ACCOUNT_ID'))+" ")])])],1)],2),_c('v-text-field',{attrs:{"label":_vm.$t('LABEL_SECRET_KEY'),"required":"","readonly":_vm.dialogAddAccountLoading},model:{value:(_vm.secretKey),callback:function ($$v) {_vm.secretKey=$$v},expression:"secretKey"}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('TOOLTIP_BUSINESS_KEY'))+" ")])])],1)],2)],1),_c('v-card-actions',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{attrs:{"color":"error","disabled":_vm.dialogAddAccountLoading},on:{"click":_vm.closeAddAccountDialog}},[_vm._v(" "+_vm._s(_vm.$t('ACTION_CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"primary","dark":"","loading":_vm.dialogAddAccountLoading},on:{"click":function($event){return _vm.addAccountConfirm(_vm.debtorAccountId, _vm.secretKey)}}},[_vm._v(" "+_vm._s(_vm.$t('ACTION_CONTINUE'))+" ")])],1)],1)],1)],1),_c('v-card-title',{staticClass:"col-sm-6 offset-sm-6"},[_c('v-text-field',{staticClass:"col-6",attrs:{"prepend-icon":"mdi-magnify","label":_vm.$t('SEARCH'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeadersToDisplay,"items":_vm.debtorAccounts,"search":_vm.search},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [(['DEBTOR_ADMIN', 'DEBTOR_APPROVER', 'DEBTOR_PAYER'].includes(item.role))?_c('span',[_vm._v(" "+_vm._s(_vm.$t(("LABEL_" + (item.role))))+" ")]):_c('span',[_vm._v(_vm._s(item.role))])]}},{key:"item.contact",fn:function(ref){
var value = ref.value;
return [(value)?_c('td',[_vm._v(_vm._s(value.title)+" "+_vm._s(value.firstName)+" "+_vm._s(value.lastName))]):_vm._e()]}},{key:"item.invoiceValidation",fn:function(ref){
var item = ref.item;
return [(item.debtorSettings.VALIDATION_REQUIRED.value)?_c('span',[_vm._v(_vm._s(_vm.$t('LABEL_YES')))]):_c('span',[_vm._v(_vm._s(_vm.$t('LABEL_NO')))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","small":"","dark":item.role === 'DEBTOR_ADMIN',"disabled":item.role !== 'DEBTOR_ADMIN',"to":{ name: 'DebtorSettingsAccountDetails', params: {
            accountId: item.billingAccountId,
            action: 'edit'
          }}}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 ",attrs:{"color":"red","small":"","disabled":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true),model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline text-center"},[_c('span',{staticClass:"force-break-word"},[_vm._v(_vm._s(_vm.$t('CONFIRMATION_DELETE_ITEM')))])]),_c('v-card-actions',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{attrs:{"color":"error","dark":""},on:{"click":_vm.closeDelete}},[_vm._v(_vm._s(_vm.$t('ACTION_CANCEL')))]),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(_vm._s(_vm.$t('ACTION_OK')))])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }