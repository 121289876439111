export const PaymentStatusesEnum = Object.freeze({
  CREATED: 'CREATED',
  READY: 'READY',
  STAND_BY: 'STAND_BY',
  AUTHORIZED: 'AUTHORIZED',
  ACCEPTED: 'ACCEPTED',
  SETTLED: 'SETTLED',
  CANCELLED: 'CANCELLED',
  REFUNDED: 'REFUNDED',
  FAILED: 'FAILED',
  CAPTURE_FAILED: 'CAPTURE_FAILED',
  CAPTURE_PENDING: 'CAPTURE_PENDING'
});

export const SuccessfulPaymentStatusesEnum = Object.freeze({
  READY: 'READY',
  AUTHORIZED: 'AUTHORIZED',
  ACCEPTED: 'ACCEPTED',
  SETTLED: 'SETTLED'
});

export const OneOffSuccessfulPaymentStatusesEnum = Object.freeze({
  AUTHORIZED: 'AUTHORIZED',
  ACCEPTED: 'ACCEPTED',
  SETTLED: 'SETTLED'
});

export const TemporaryPaymentStatusesEnum = Object.freeze({
  CREATED: 'CREATED',
  STAND_BY: 'STAND_BY',
  CAPTURE_PENDING: 'CAPTURE_PENDING'
});