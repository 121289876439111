import Service from './Service';

class SentMessageService extends Service {
  getSentMessages (invoiceId, creditorId) {
    return this.instance.get('eventapi/public/v2/sentMessages', {
      params: {
        invoiceId: invoiceId,
        creditorId: creditorId
      }
    });
  }
}

export default new SentMessageService();
