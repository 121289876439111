<template>
  <v-container v-if='payment' :key='$route.params.transactionReference'>
    <v-row class="my-1">
      <v-btn text @click="$router.go(-1)" color="primary">
        <v-icon left>mdi-arrow-left-bold</v-icon>
        <strong>{{ $t('BACK') }}</strong>
      </v-btn>
    </v-row>
    <v-row class='mt-5'>
      <h2>{{ $t('PAYMENT_DETAIL') }}</h2>
    </v-row>
    <v-card class='mt-5'>
      <v-row>
        <v-col offset-md="1">
          <dt>
            <strong>{{ $t('PAYMENT_REFERENCE') }}</strong>
          </dt>
          <dd>
            <span class="overline" v-if="payment.id !== undefined"> {{ 'P' + payment.id.toString().padStart(6, '0') }} </span>
          </dd>
        </v-col>
        <v-col>
          <dt>
            <strong>{{ $t('USER') }} </strong>
          </dt>
          <dd>
             {{ payment.creationUserName }}
          </dd>
        </v-col>
        <v-col>
          <dt>
            <strong>{{ $t('PAYMENT_DATE') }}</strong>
          </dt>
          <dd>
            {{ payment.transactionDate | date }}
          </dd>
        </v-col>
        <v-col>
          <dt>
            <strong>{{ $t('STATUS') }}</strong>
          </dt>
          <dd>
            {{ computePaymentStatusLabel(payment.status) }}
          </dd>
        </v-col>
      </v-row>
      <v-row>
        <v-col offset-md="1">
          <dt>
            <strong>{{ $t('TRANSACTION_REFERENCE') }}</strong>
          </dt>
          <dd>
            {{ payment.transactionReference }}
          </dd>
        </v-col>
        <v-col>
          <dt>
            <strong>{{ $t('PAYMENT_MEAN') }}</strong>
          </dt>
          <dd>
            {{ $t(payment.paymentMeanI18nTag) }}
          </dd>
        </v-col>
        <v-col>
          <dt>
            <strong>{{ $t('LABEL_AMOUNT_INCLUDING_TAXES') }}</strong>
          </dt>
          <dd>
            {{ payment.amount | currency(payment.currency) }}
          </dd>
        </v-col>
        <v-col>
          <dt>
            <strong>{{ $t('CREDITOR_ACCOUNT') }}</strong>
          </dt>
          <dd>
            {{ payment.creditor.accountId }}
          </dd>
        </v-col>
      </v-row>
      <v-row class='mt-5 ml-5'>
        <v-col cols="12">
          <h3>{{ $t('LINKED_DOCUMENTS') }}</h3>
        </v-col>
      </v-row>
      <v-data-table
          :headers="headers"
          :items="linkedDocuments"
          :loading="loading"
          :sort-by.sync="defaultSortBy"
          sort-desc
          disable-pagination
          :hide-default-footer=true
      >
        <template v-slot:item.invoiceName="{ item }">
          <router-link :to='{
               name: invoiceDetailRouteName,
               params: { id: item.id },
             }'
          >
            {{ item.invoiceName }}
          </router-link>
        </template>
        <template v-slot:item.dueDate="{ item }">
          <span>{{ item.dueDate | date }}</span>
        </template>
        <template v-slot:item.status="{ item }">
          <span>{{ $t(item.status) }}</span>
        </template>
        <template v-slot:item.amount="{ item }">
          <span v-if="isCreditNote(item.invoiceCode)" class="green--text">{{parseInt(-item.amount) | currency(item.currency)}}</span>
          <span v-else>{{item.amount | currency(item.currency)}}</span>
        </template>
      </v-data-table>
    </v-card>

    <v-card class='mt-5'>
      <v-card-title>
      {{ $t('PAYMENT_EVENT') }}
      </v-card-title>
      <v-timeline
          dense
      >
        <v-timeline-item
            small
            right
            v-for='history in sortingHistory'
            :key='history.date'
        >
          {{ history.userName }}
          <v-spacer></v-spacer>
          {{ history.date | dateTime }}
          <v-spacer></v-spacer>
          {{ computePaymentStatusLabel(history.status) }}
        </v-timeline-item>
      </v-timeline>
    </v-card>
  </v-container>
</template>

<script>
import dateMixin from '@/mixins/dateMixin';
import numberMixin from '@/mixins/numberMixin';
import userMixin from '@/mixins/userMixin';
import paymentOperationService from '@/services/paymentOperationService';
import paymentMixin from '@/mixins/paymentMixin';
import InvoiceUtils from '@/utils/invoiceUtils';
import { mapState } from 'vuex';

export default {
  name: 'PaymentDetail',
  mixins: [dateMixin, numberMixin, userMixin, paymentMixin],
  computed: {
    ...mapState(['selectedDebtorAccount']),
    sortingHistory: function () {
      const paymentHistorySorted = [...this.paymentHistory];
      return paymentHistorySorted.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    },
    invoiceDetailRouteName () {
      return this.isCreditor() ? 'CreditorInvoiceDetail' : 'DebtorInvoiceDetail';
    }
  },
  data () {
    return {
      defaultSortBy: 'dueDate',
      linkedDocuments: [],
      loading: true,
      payment: [],
      paymentHistory: [],
      headers: [
        {
          text: this.$t('LABEL_DOCUMENT_REFERENCE'),
          value: 'invoiceName'
        },
        {
          text: this.$t('INVOICE_DUE_DATE'),
          value: 'dueDate',
          align: 'center'
        },
        {
          text: this.$t('STATUS'),
          value: 'status',
          align: 'center'
        },
        {
          text: this.$t('LABEL_AMOUNT_INCLUDING_TAXES'),
          value: 'amount',
          align: 'center'
        }
      ]
    };
  },
  watch: {
    '$route.params.transactionReference': {
      immediate: true,
      async handler () {
        this.getPaymentDetails(this.$route.params.transactionReference);
      }
    }
  },
  methods: {
    async getPaymentDetails (transactionReference) {
      try {
        const { data } = await paymentOperationService.getPaymentOperationByTransactionReference(transactionReference);
        this.payment = data;
        this.linkedDocuments = this.payment.documents;
        this.getPaymentEvents(this.payment.id);
      } catch (err) {
        this.handleError(err);
      }
    },
    async getPaymentEvents (paymentId) {
      try {
        const { data } = await paymentOperationService.getPaymentOperationHistory(paymentId);
        this.paymentHistory = data;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    },
    isCreditNote (invoiceCode) {
      return InvoiceUtils.isCreditNote(invoiceCode);
    }
  }
};
</script>