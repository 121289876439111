import { PaymentStatusesEnum } from '@/enums/paymentStatusesEnum';

export default {
  data () {
    return {
      paymentMeans: {
        CREDIT_CARD: {
          value: 'CREDIT_CARD',
          icon: 'mdi-credit-card'
        },
        PIS: {
          value: 'PIS',
          icon: 'mdi-bank-transfer'
        },
        SEPA_DD: {
          value: 'SEPA_DD',
          icon: 'mdi-send-clock'
        },
        CREDIT_NOTE: {
          value: 'CREDIT_NOTE',
          icon: 'mdi-text-box-check-outline'
        },
        TRANSFER: {
          value: 'TRANSFER',
          icon: 'mdi-bank-transfer'
        }
      }
    };
  },
  methods: {
    computePaymentStatusLabel (status) {
      if (Object.values(PaymentStatusesEnum).includes(status)) {
        return this.$t('PAYMENT_STATUS_' + status);
      }
    }
  }
};