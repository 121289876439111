<template>
  <div class="fill-height">
    <v-row
      align="center"
      class="d-none d-sm-flex py-1 pt-3"
      v-if="!hideMainNavigation && showDebtorMenu">
      <AppDebtorMenu/>
    </v-row>
    <v-row class="fill-height">
      <v-col
        sm="2"
        class="d-none d-md-block right-border pr-0 pl-2"
        v-if="!hideMainNavigation && showDebtorSelector">
        <DebtorSelector></DebtorSelector>
      </v-col>
      <v-col
        class="mt-5"
        cols="12"
        :md="(hideMainNavigation || !showDebtorSelector) ? 12 : 10">
        <div class="container"
             v-if="hideMainNavigation">
          <v-btn
            color="primary"
            class="back-home-button"
            :to="{ name: 'DebtorHome' }">
            <v-icon left>
              mdi-arrow-left-bold
            </v-icon>
            {{ $t("BACK_TO_HOME") }}
          </v-btn>
        </div>
        <router-view/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppDebtorMenu from '@/components/common/menus/AppDebtorMenu';
import DebtorSelector from '@/components/debtor/DebtorSelector';
import { mapState } from 'vuex';

export default {
  components: {
    AppDebtorMenu,
    DebtorSelector
  },
  computed: {
    ...mapState(['debtorAccounts']),
    showDebtorMenu () {
      return this.debtorAccounts?.length;
    },
    showDebtorSelector () {
      return this.debtorAccounts?.length > 1;
    },
    hideMainNavigation () {
      return this.$route.matched.some(route => route.meta.hideMainNavigation);
    }
  }
};
</script>

<style scoped>
.right-border {
  border-right: 1px lightgray solid;
}

.back-home-button::before {
  opacity: 0;
}
</style>