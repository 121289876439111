var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"bottom-sheet-transition"}},[(_vm.isDocumentSelection)?_c('div',{staticClass:"v-dialog__content v-dialog__content--active custom-bottom-sheet-wrapper",attrs:{"role":"document","tabindex":"0"}},[_c('div',{staticClass:"v-dialog v-dialog--active v-dialog--persistent v-bottom-sheet d-flex custom-bottom-sheet"},[_c('v-container',{staticClass:"d-flex py-0 align-center"},[_c('div',{staticClass:"d-block align-self-center",class:_vm.isOnMobileSupport ? 'col-5' : 'col-4'},[_c('div',{staticClass:"font-weight-bold",class:_vm.isOnMobileSupport ? 'text-h8' : 'text-h6'},[_vm._v(_vm._s(_vm.selectedDocuments.length || _vm.$t('NO'))+" "+_vm._s(_vm.$tc('DOCUMENT_SELECTED', _vm.selectedDocuments.length)))]),_c('div',[_vm._v(_vm._s(_vm.$t('LABEL_TOTAL'))+" "+_vm._s(_vm._f("currency")(_vm.totalSelectionAmount,_vm.selectedDebtorAccount.currency)))])]),(!_vm.isOnMobileSupport || _vm.isScreenRotated)?_c('div',{staticClass:"d-block align-self-center",class:_vm.isOnMobileSupport ? 'col-5' : 'col-4'},[_c('div',[_vm._v(_vm._s(_vm.$t('SELECTED_INVOICES_AMOUNT'))+" "+_vm._s(_vm._f("currency")(_vm.totalInvoicesAmount,_vm.selectedDebtorAccount.currency)))]),_c('div',[_vm._v(_vm._s(_vm.$t('SELECTED_CREDIT_NOTES_AMOUNT'))+" "+_vm._s(_vm._f("currency")(-_vm.totalCreditNotesAmount,_vm.selectedDebtorAccount.currency)))])]):_c('div',{staticClass:"col-3 d-flex align-self-center justify-space-around"},[_c('v-tooltip',{attrs:{"open-on-click":"","open-on-hover":false,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","small":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('BUTTON_DETAILS'))+" ")])]}}],null,false,2641763148)},[_c('span',[_vm._v(_vm._s(_vm.$t('SELECTED_INVOICES_AMOUNT'))+" "+_vm._s(_vm._f("currency")(_vm.totalInvoicesAmount,_vm.selectedDebtorAccount.currency)))]),_c('v-spacer'),_c('span',[_vm._v(_vm._s(_vm.$t('SELECTED_CREDIT_NOTES_AMOUNT'))+" "+_vm._s(_vm._f("currency")(-_vm.totalCreditNotesAmount,_vm.selectedDebtorAccount.currency)))])],1)],1),(_vm.isOnMobileSupport)?_c('div',{staticClass:"d-flex justify-space-around align-self-center",class:_vm.isScreenRotated ? 'col-2' : 'col-5'},[_c('v-btn',{staticClass:"mb-1",attrs:{"color":"secondary","small":"","disabled":!_vm.enablePayment},on:{"click":function($event){return _vm.payInvoices(_vm.selectedDocuments)}}},[_vm._v(" "+_vm._s(_vm.$t('ACTION_PAY'))+" ")]),_c('InvoicesRejectionDialog',{attrs:{"privateEventBus":_vm.privateEventBus,"invoicesToValidate":_vm.selectedDocuments},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var rejectDialog = ref.on;
var rejection = ref.attrs;
return [_c('InvoicesValidationDialog',{attrs:{"privateEventBus":_vm.privateEventBus,"invoicesToValidate":_vm.selectedDocuments},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var validationDialog = ref.on;
var validation = ref.attrs;
return [_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, menu)),[_c('v-icon',{staticClass:"mb-2",attrs:{"color":"primary"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',_vm._g(_vm._b({staticClass:"text-center",attrs:{"link":""}},'v-list-item',Object.assign({}, rejection),false),Object.assign({}, rejectDialog)),[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('ACTION_REJECT'))}})],1),(_vm.toValidatePage)?_c('v-list-item',_vm._g(_vm._b({staticClass:"text-center",attrs:{"link":""}},'v-list-item',Object.assign({}, validation),false),Object.assign({}, validationDialog)),[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('ACTION_VALIDATE'))}})],1):_vm._e()],1)],1)]}}],null,true)})]}}],null,false,11638953)})],1):_c('div',{staticClass:"d-flex justify-end align-self-center col-4"},[_c('InvoicesRejectionDialog',{attrs:{"privateEventBus":_vm.privateEventBus,"invoicesToReject":_vm.selectedDocuments},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"secondary","disabled":!_vm.enableValidationOrRejection}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('ACTION_REJECT'))+" ")])]}}],null,false,3070477136)}),_c('v-btn',{staticClass:"ml-4",attrs:{"outlined":_vm.toValidatePage,"color":"secondary","disabled":!_vm.enablePayment},on:{"click":function($event){return _vm.payInvoices(_vm.selectedDocuments)}}},[_vm._v(" "+_vm._s(_vm.$t('ACTION_PAY'))+" ")]),(_vm.toValidatePage)?_c('InvoicesValidationDialog',{attrs:{"privateEventBus":_vm.privateEventBus,"invoicesToValidate":_vm.selectedDocuments},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"color":"secondary","disabled":!_vm.enableValidationOrRejection}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('ACTION_VALIDATE'))+" ")])]}}],null,false,979593194)}):_vm._e()],1)])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }