<template>
  <v-dialog v-model="displayDialog" persistent max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="action === 'edit'"
        v-on="on"
        v-bind="attrs"
        class="ma-2 button-minified"
        color="primary"
        dark
        small
      >
        <v-icon dark small>
          mdi-pencil
        </v-icon>
      </v-btn>
    </template>
    <v-card v-if="displayDialog">
      <v-card-title class="pa-4 text-h6 force-break-word">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text>
        <v-form
            ref="form"
            v-model="valid"
            class="pa-4"
            @input="validateForm()"
        >
          <v-select
              v-model="debtorUser.gender"
              :items="titleItems"
              :rules="[rules.requiredFieldRules]"
              :label="$t('LABEL_TITLE')"
              :disabled="existingUser"
              required
          ></v-select>

          <v-text-field
              v-model="debtorUser.lastName"
              :rules="[rules.requiredFieldRules, rules.nameRules, rules.nameRegex]"
              :label="$t('LABEL_LASTNAME')"
              :disabled="existingUser"
              counter="20"
              required
          ></v-text-field>

          <v-text-field
              v-model="debtorUser.firstName"
              :rules="[rules.requiredFieldRules, rules.nameRules, rules.nameRegex]"
              :label="$t('LABEL_FIRSTNAME')"
              :disabled="existingUser"
              counter="20"
              required
          ></v-text-field>

          <PhoneNumberInput :phone-number.sync="phoneNumber"
                            :disabled="existingUser"
                            :phone-mask-key.sync="debtorUser.phoneMaskKey"
                            :creditorId="this.creditor.id">
          </PhoneNumberInput>

          <v-text-field
              v-model="debtorUser.email"
              :rules="[rules.emailRules]"
              :label="$t('LABEL_EMAIL')"
              disabled
              required
          ></v-text-field>

          <v-select
              v-model="debtorUser.mainRole"
              :items="availableDebtorRoles"
              :rules="[rules.requiredFieldRules]"
              :label="$t('LABEL_ROLE')"
              required>
          </v-select>

          <v-row
              justify="space-around">
            <v-btn
                color="error"
                @click="closeDialog"
                class="my-4"
            >
              {{ $t('ACTION_CANCEL') }}
            </v-btn>
            <v-btn
                :disabled="!valid"
                :loading="validateLoading"
                color="primary"
                @click="validate"
                class="my-4"
            >
              {{ $t('ACTION_VALIDATE') }}
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import userMixin from '@/mixins/userMixin';
import userService from '@/services/userService';
import userMapper from '@/mappers/userMapper';
import cloneDeep from 'lodash/cloneDeep';
import maskMixin from '@/mixins/maskMixin';
import ruleMixin from '@/mixins/ruleMixin';
import PhoneNumberInput from '@/components/common/PhoneNumberInput';
import { mapState } from 'vuex';

export default {
  mixins: [userMixin, maskMixin, ruleMixin],
  components: { PhoneNumberInput },
  props: {
    action: {
      type: String,
      required: true
    },
    userProp: {
      type: Object,
      required: true
    },
    debtorId: {
      required: true
    }
  },
  data () {
    return {
      debtorUser: {},
      displayDialog: false,
      valid: undefined,
      phoneNumber: undefined,
      validateLoading: false,
      titleItems: [
        {
          value: 'MISS',
          text: this.$t('MISS')
        },
        {
          value: 'MS',
          text: this.$t('MS')
        },
        {
          value: 'MR',
          text: this.$t('MR')
        }
      ]
    };
  },
  computed: {
    newUser () {
      return this.action === 'add' && !this.debtorUser.id;
    },
    existingUser () {
      return !!(this.action === 'add' && this.debtorUser.id);
    },
    editUser () {
      return this.action === 'edit';
    },
    dialogTitle () {
      let title = '';
      if (this.newUser) {
        title = this.$t('TITLE_NEW_USER');
      } else if (this.existingUser) {
        title = this.$t('TITLE_EXISTING_USER');
      } else if (this.editUser) {
        title = this.$t('TITLE_EDIT_USER');
      }
      return title;
    },
    ...mapState(['creditor'])
  },
  watch: {
    userProp: {
      deep: true,
      immediate: true,
      handler () {
        this.debtorUser = cloneDeep(this.userProp);
        if (this.debtorUser.phoneNumber) {
          this.phoneNumber = this.debtorUser.phoneNumber;
        }
      }
    }
  },
  methods: {
    // Allow to display all form errors without clicking on every input
    validateForm () {
      this.$refs.form?.validate();
    },
    async validate () {
      this.debtorUser.phoneNumber = this.replaceWithoutBlank(this.phoneNumber);
      this.validateLoading = true;
      if (this.editUser) {
        try {
          await userService.updateDebtorUser(this.debtorUser, this.debtorId);
          await userService.assignRoleToUser(this.debtorUser.id, this.debtorId,
            this.debtorUser.mainRole);
          this.displaySuccessSnackbar(this.$t('SUCCESS_MESSAGE_EDIT_USER'));
          this.closeDialog();
          this.$refs.form.reset();
        } catch (err) {
          this.handleError(err);
        }
      } else if (this.existingUser) {
        try {
          await userService.linkUserToDebtorAccount(this.debtorUser.id, this.debtorId,
            this.debtorUser.mainRole);
          this.displaySuccessSnackbar(this.$t('DEBTOR_SUCCESS_MESSAGE_ASSOCIATE_USER'));
          this.closeDialog();
        } catch (err) {
          this.handleError(err);
        }
      } else if (this.newUser) {
        try {
          await userService.createDebtorUser(userMapper.mapToKeycloakUser(this.debtorUser, this.debtorUser.mainRole),
            this.debtorId);
          this.displaySuccessSnackbar(this.$t('DEBTOR_SUCCESS_MESSAGE_NEW_USER'));
          this.closeDialog();
        } catch (err) {
          this.handleError(err);
        }
      }
      this.validateLoading = false;
      this.$emit('refresh-table');
    },
    openDialog () {
      this.displayDialog = true;
    },
    closeDialog () {
      this.displayDialog = false;
      if (this.action === 'add') {
        this.$refs.form.reset();
      }
    }
  }
};
</script>