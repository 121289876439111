<template>
  <v-dialog v-model="dissociateUserDialog" persistent max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          v-on="on"
          v-bind="attrs"
          class="ma-2 button-minified"
          color="red"
          dark
          small>
        <v-icon dark small>
          mdi-trash-can-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline text-center">
        <span class="force-break-word">{{ $t('CONFIRMATION_DISSOCIATE_USER', { firstName: userProp.firstName, lastName: userProp.lastName }) }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-space-around">
        <v-btn color="error" dark @click.stop="dissociateUserDialog = false">
          {{ $t('ACTION_CANCEL') }}
        </v-btn>
        <v-btn color="primary"
          dark
          @click="dissociateDebtorUser(userProp)"
          :loading="dissociateLoading"
        >
          {{ $t('ACTION_VALIDATE') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import userService from '@/services/userService';

export default {
  props: {
    userProp: {
      type: Object,
      required: true
    },
    debtorId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      dissociateUserDialog: false,
      dissociateLoading: false
    };
  },
  methods: {
    async dissociateDebtorUser (user) {
      this.dissociateLoading = true;
      try {
        await userService.dissociateUserFromDebtorAccount(user.id, this.debtorId);
        this.displaySuccessSnackbar(this.$t('SUCCESS_MESSAGE_DISSOCIATE_DEBTOR_ACCOUNT'));
        this.$emit('refresh-table');
      } catch (err) {
        this.handleError(err);
      }
      this.dissociateUserDialog = false;
      this.dissociateLoading = false;
    }
  }
};
</script>