<template>
  <v-container v-if="selectedDebtorAccount">
    <v-row>
      <v-col cols="12">
        <InvoicePaidTable :debtorAccountId="selectedDebtorAccount.billingAccountId" :routeDetail="'DebtorInvoiceDetail'">
        </InvoicePaidTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import InvoicePaidTable from '@/components/common/InvoicePaidTable';

export default {
  name: 'DebtorPaidInvoices',
  components: { InvoicePaidTable },
  computed: {
    // TODO: Correct the accountId when the API is homogeneous,
    // 2 API calls return the value of accountId in différents fields. AccountId must be return in the same field.
    ...mapState(['selectedDebtorAccount'])
  }
};
</script>
