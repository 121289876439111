<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <ExportCsvButton
                :disable="payments ? false : true"
                :loadingData="loading"
                :headers="headers.filter(header => header.exportable)"
                :invoices="payments"
                :filename="$t('PAYMENTS')">
            </ExportCsvButton>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify"
                :label="$t('SEARCH')"
                class="search-bar"
                single-line
                hide-details
                clearable
                @input="getPayments()"
            ></v-text-field>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="payments"
              :options.sync="options"
              :footer-props="footerProps"
              :server-items-length="totalPayments"
              :sort-by="defaultSortBy"
              sort-desc
              :loading="loading"
          >
            <template v-slot:item.id="{ item }">
              <span class="overline">{{ 'P' + item.id.toString().padStart(6, '0') }}</span>
            </template>

            <template v-slot:item.creationDate="{ item }">
              {{ item.creationDate | date }}
            </template>

            <template v-slot:item.status="{ item }">
              {{ computePaymentStatusLabel(item.status) }}
            </template>

            <template v-slot:item.paymentMeanType="{ item }">
              {{ $t(item.paymentMeanI18nTag) }}
            </template>

            <template v-slot:item.amount="{ item }">
              <strong class="black--text">{{ item.amount | currency(item.currency) }}</strong>
            </template>

            <template v-slot:item.action="{ item }">

              <v-btn
                  color="primary"
                  :to="{ name: 'DebtorPaymentDetail', params: { transactionReference: item.transactionReference }}"
              >
                {{ $t('BUTTON_DETAILS') }}
              </v-btn>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import paymentOperationService from '@/services/paymentOperationService';
import ExportCsvButton from '@/components/common/buttons/ExportCsvButton';
import { mapState } from 'vuex';
import dateMixin from '@/mixins/dateMixin';
import numberMixin from '@/mixins/numberMixin';
import userMixin from '@/mixins/userMixin';
import paymentMixin from '@/mixins/paymentMixin';

export default {
  components: { ExportCsvButton },
  mixins: [dateMixin, numberMixin, userMixin, paymentMixin],
  data () {
    return {
      loading: true,
      lastSortingOrder: '',
      lastSorting: '',
      options: {},
      payments: [],
      defaultSortBy: 'creationDate',
      search: '',
      totalPayments: 0,
      footerProps: {
        'items-per-page-options': [5, 10, 20, 50, 100]
      },
      headers: [
        {
          text: this.$t('PAYMENT_REFERENCE'),
          value: 'id',
          exportable: true
        },
        {
          text: this.$t('USER'),
          value: 'creationUserName',
          sortable: false,
          exportable: true
        },
        {
          text: this.$t('PAYMENT_DATE'),
          value: 'creationDate',
          align: 'center',
          exportable: true
        },
        {
          text: this.$t('STATUS'),
          value: 'status',
          exportable: true
        },
        {
          text: this.$t('TRANSACTION_REFERENCE'),
          value: 'transactionReference',
          exportable: true
        },
        {
          text: this.$t('PAYMENT_MEAN'),
          value: 'paymentMeanType',
          align: 'center',
          sortable: false,
          exportable: true
        },
        {
          text: this.$t('LABEL_AMOUNT_INCLUDING_TAXES'),
          value: 'amount',
          align: 'end',
          exportable: true
        },
        {
          text: this.$t('CREDITOR_ACCOUNT'),
          value: 'creditor.accountId',
          sortable: false,
          exportable: true
        },
        {
          text: this.$t('LABEL_ACTIONS'),
          value: 'action',
          sortable: false,
          exportable: false
        }
      ]
    };
  },
  methods: {
    async getPayments () {
      this.loading = true;
      if (this.search === null) {
        this.search = '';
      }
      try {
        const { data } = await paymentOperationService.getPaginatedAndSortedPaymentsByDebtorId(this.selectedDebtorAccount.id, this.options, this.search);
        this.payments = data.content;
        if (this.payments !== undefined) {
          this.totalPayments = data.totalElements;
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapState(['selectedDebtorAccount'])
  },
  watch: {
    options: {
      deep: true,
      handler () {
        this.getPayments();
      }
    },
    selectedDebtorAccount () {
      this.getPayments();
    }
  }
};

</script>

<style scoped>

.search-bar {
  margin-right: auto;
}
</style>