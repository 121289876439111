<template>
  <v-container v-if='invoice' :key='$route.params.id'>
    <v-row class="my-1">
      <v-btn text @click="$router.go(-1)" color="primary">
        <v-icon left>mdi-arrow-left-bold</v-icon>
        <strong>{{ $t('BACK') }}</strong>
      </v-btn>
    </v-row>
    <v-row class='ma-auto'>
      <h2>{{ $t('DOCUMENTS_DETAILS') }}</h2>
    </v-row>
    <v-card class='mt-5'>
      <v-card-title>{{ cardTitle }}
        <v-spacer></v-spacer>
        <v-btn @click='getFileContent' v-if='invoice.pdfFilename'>
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-btn v-if="isOnMobileSupport" @click='toogleSecondaryData' class='ml-3'>
          <v-icon v-if="showSecondaryData">mdi-minus</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class='ma-auto'>
        <v-col>
          <dt class='font-weight-bold'>
            {{ $t('INVOICE_REFERENCE') }}
          </dt>
          <dd >{{ $t(documentType) }} {{ invoice.id }}</dd>
        </v-col>
        <v-col>
          <dt class='font-weight-bold'>
            {{ $t('STATUS') }}
          </dt>
          <dd>{{ $t(invoice.status) }}</dd>
        </v-col>
        <v-col v-if='showSecondaryData || !isOnMobileSupport'>
          <dt class='font-weight-bold'>
            {{ $t('INVOICE_ISSUE_DATE') }}
          </dt>
          <dd>
            {{ invoice.issueDate | date }}
          </dd>
        </v-col>
        <v-col v-if='invoice.isInvoice && (showSecondaryData || !isOnMobileSupport)'>
          <dt class='font-weight-bold'>
            {{ $t('INVOICE_INITIAL_AMOUNT_HT') }}
          </dt>
          <dd>
            {{ invoice.amountExclusiveOfTax | currency(invoice.currency) }}
          </dd>
        </v-col>
        <v-col>
          <dt class='font-weight-bold'>
            {{ $t('INVOICE_INITIAL_AMOUNT_TTC') }}
          </dt>
          <dd>
            {{ invoice.amountWithTax | currency(invoice.currency) }}
          </dd>
        </v-col>
        <v-col v-if='invoice.isInvoice'>
          <dt class='font-weight-bold'>
            {{ $t('INVOICE_DUE_DATE') }}
          </dt>
          <dd v-if='isValidatedStatus || isToPayStatus'><DueDate v-bind:due-date="invoice.dueDate"></DueDate></dd>
          <dd v-else>
            {{ invoice.dueDate | date }}
          </dd>
        </v-col>
        <v-col v-if='!isPaidStatus'>
          <dt class='font-weight-bold'>
            {{ $t('INVOICE_OUTSTANDING') }}
          </dt>
          <dd>
            {{ invoice.temporaryOutstandingAmount | currency(invoice.currency) }}
          </dd>
        </v-col>
        <v-col v-if='isPaidStatus'>
          <dt class='font-weight-bold'>
            {{ $t('PAYMENT_DATE') }}
          </dt>
          <dd v-if="invoice.isPaymentValidated">
            <v-icon color="blue" small v-if="invoice.isPaymentAccepted">mdi-clock-alert-outline</v-icon>{{ invoice.paymentDate | date }}
          </dd>
        </v-col>
      </v-row>

      <v-row class='ma-auto' v-if="isCreditor() && invoice.oneOffPaymentLink !== undefined">
        <v-col>
          <dt class='font-weight-bold'>
            {{ $t('PAYMENT_LINK_LABEL') }}
          </dt>
          <dd>
            <span>{{ invoice.oneOffPaymentLink }}</span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    x-small
                    fab
                    class="ml-3"
                    elevation="1"
                    v-on="on"
                    v-bind="attrs"
                    @click="copyOneOffLinkToClipboard"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('COPY_TO_CLIPBOARD_TOOLTIP') }}</span>
            </v-tooltip>
          </dd>
        </v-col>
      </v-row>

      <v-row class='mt-5'>
        <v-col v-if="invoice.linkedInvoices && invoice.linkedInvoices.length" md='4' class='ml-5'>
          <h3>{{ $t('LINKED_DOCUMENTS') }}</h3>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for='creditNote in invoice.linkedInvoices'
                :key='creditNote.id'
              >
                <router-link
                  :to='{
                    name: currentRouteName,
                    params: { id: creditNote.id },
                  }'
                >
                  {{ creditNote.invoiceNumber }}
                </router-link>
                <dl
                  v-for='linkedInvCreditNote in creditNote.linkedInvoice'
                  :key='linkedInvCreditNote.id'
                >
                  <dt class='sr-only'>
                    {{ $t('INVOICE_REFERENCE') }}
                  </dt>
                  <dd>
                    <p>{{ linkedInvCreditNote.invoiceNumber }}</p>
                  </dd>
                </dl>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col v-if='comment' md='7' class="ml-5">
          <h3>{{ $t('INVOICE_COMMENT') }}</h3>
          <v-card-text>{{ comment }}</v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <v-card class='mt-5'>
      <v-card-title> {{ $t('PAYMENT_BOX') }} </v-card-title>
      <v-data-table
          :headers="headers"
          :items="paymentOperations"
          :loading="paymentListLoading"
          :sort-by.sync="defaultSortBy"
          sort-desc
          disable-pagination
          :hide-default-footer=true
      >
        <template v-slot:item.transactionReference="{ item }">
          <router-link :to="{ name: paymentDetailRouteName, params: { transactionReference: item.transactionReference }}">
            {{ item.transactionReference }}
          </router-link>
        </template>
        <template v-slot:item.creationDate="{ item }">
          {{ item.creationDate | date }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ computePaymentStatusLabel(item.status) }}
        </template>
        <template v-slot:item.paymentMeanType="{ item }">
          {{  $t(item.paymentMeanI18nTag) }}
        </template>
        <template v-slot:item.amount="{ item }">
          {{ item.amount | currency(invoice.currency) }}
        </template>
      </v-data-table>
    </v-card>

    <v-card class='mt-5'>
      <v-card-title
        >{{ $t('OPERATION_HISTORY') }}
        <v-card-actions>
          <v-btn
              icon
              @click="showOperationHistory = !showOperationHistory"
          >
            <v-icon v-if="showOperationHistory">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-title>
      <v-expand-transition>
        <div v-if='documentEvents && showOperationHistory'>
          <v-timeline dense>
            <v-timeline-item
                small
                right
                v-for='event in documentEvents'
                :key='event.id'
            >
              <dt>
                {{ event.eventDate | dateTime }}
              </dt>
              <dd>{{ getEventDescription(event) }}</dd>
            </v-timeline-item>
          </v-timeline>
        </div>
      </v-expand-transition>
    </v-card>

    <v-card class='mt-5' v-if="isCreditor()">
      <v-card-title>
        {{ $t('NOTIFICATION_HISTORY') }}
        <v-card-actions>
          <v-btn
              icon
              @click="showNotificationHistory = !showNotificationHistory"
          >
            <v-icon v-if="showNotificationHistory">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-title>
      <v-expand-transition>
        <v-data-table
            class="notificationHistoryTable"
            :headers="notificationHeaders"
            :items="sentMessages"
            :loading="notificationHistoryLoading"
            :sort-by.sync="notificationsDefaultSortBy"
            sort-desc
            disable-pagination
            :hide-default-footer=true
            v-show="showNotificationHistory"
        >
          <template v-slot:item.dispatchDate="{ item }">
            {{ item.dispatchDate | dateTime }}
          </template>
          <template v-slot:item.channel="{ item }">
            {{ item.channel }}
          </template>
          <template v-slot:item.type="{ item }">
            {{ notificationTypeWording(item.type) }}
          </template>
          <template v-slot:item.templateName="{ item }">
            {{ item.templateName }}
          </template>
          <template v-slot:item.recipient="{ item }">
            {{ item.debtorPhone || item.debtorEmail }}
          </template>
        </v-data-table>
      </v-expand-transition>
    </v-card>
  </v-container>
</template>

<script>
import dateMixin from '@/mixins/dateMixin';
import numberMixin from '@/mixins/numberMixin';
import userMixin from '@/mixins/userMixin';
import notificationTypeMixin from '@/mixins/notificationTypeMixin';
import eventService from '@/services/eventService';
import invoiceService from '@/services/invoiceService';
import sentMessageService from '@/services/sentMessageService';
import paymentOperationService from '@/services/paymentOperationService';
import invoiceMapper from '@/mappers/invoiceMapper';
import DueDate from '@/components/common/DueDate';
import { mapState } from 'vuex';
import eventMixin from '@/mixins/eventMixin';

export default {
  name: 'InvoiceDetail',
  mixins: [dateMixin, numberMixin, userMixin, eventMixin, notificationTypeMixin],
  components: { DueDate },
  computed: {
    ...mapState(['selectedDebtorAccount', 'selectedCreditorAccount']),
    isPaidStatus () {
      return this.invoice.status === 'PAID' || this.invoice.status === 'SETTLED';
    },
    isValidatedStatus () {
      return this.invoice.status === 'VALIDATED';
    },
    isToPayStatus () {
      return this.invoice.status === 'TO_PAY';
    },
    paymentDetailRouteName () {
      return this.isCreditor() ? 'CreditorPaymentDetail' : 'DebtorPaymentDetail';
    }
  },
  data () {
    return {
      btnLabel: '',
      cardTitle: '',
      comment: '',
      currentRouteName: '',
      defaultSortBy: 'creationDate',
      notificationsDefaultSortBy: 'dispatchDate',
      documentEvents: [],
      documentType: '',
      invoice: {},
      paymentListLoading: true,
      notificationHistoryLoading: true,
      paymentOperations: [],
      headers: [
        {
          text: this.$t('PAYMENT_REFERENCE'),
          value: 'transactionReference'
        },
        {
          text: this.$t('PAYMENT_DATE'),
          value: 'creationDate',
          align: 'center'
        },
        {
          text: this.$t('STATUS'),
          value: 'status'
        },
        {
          text: this.$t('PAYMENT_MEAN'),
          value: 'paymentMeanType'
        },
        {
          text: this.$t('AMOUNT'),
          value: 'amount'
        }
      ],
      notificationHeaders: [
        {
          text: this.$t('DATE'),
          value: 'dispatchDate',
          class: 'sticky-header white'
        },
        {
          text: this.$t('CHANNEL'),
          value: 'channel',
          class: 'sticky-header white'
        },
        {
          text: this.$t('TYPE'),
          value: 'type',
          class: 'sticky-header white'
        },
        {
          text: this.$t('TEMPLATE'),
          value: 'templateName',
          class: 'sticky-header white'
        },
        {
          text: this.$t('RECIPIENT'),
          value: 'recipient',
          class: 'sticky-header white'
        }
      ],
      sentMessages: [],
      showSecondaryData: false,
      showNotificationHistory: false,
      showOperationHistory: false
    };
  },
  async beforeMount () {
    this.currentRouteName = this.$router.currentRoute.name;
  },
  watch: {
    '$route.params.id': {
      handler: function () {
        this.getInvoiceDetails(this.$route.params.id);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async getPaymentList (invoiceId) {
      try {
        const { data } = await paymentOperationService.getPaymentOperationByInvoiceId(invoiceId);
        this.paymentOperations = data;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.paymentListLoading = false;
      }
    },
    async getSentMessagesHistory (invoiceId) {
      try {
        const { data } = await sentMessageService.getSentMessages(invoiceId, this.selectedCreditorAccount.id);
        this.sentMessages = data;
      } catch (err) {
        this.handleError(err);
      } finally {
        this.notificationHistoryLoading = false;
      }
    },
    async getInvoiceDetails (documentId) {
      try {
        const { data } = await invoiceService.getInvoiceDetails(documentId);
        this.invoice = invoiceMapper.mapOne(data);
        this.setCardTitleAndLabels();
        this.getComment();
        await this.getDocumentEvents();
        await this.getPaymentList(this.invoice.id);
        if (this.isCreditor()) {
          await this.getSentMessagesHistory(this.invoice.id);
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    setCardTitleAndLabels () {
      this.cardTitle = this.$t(this.documentType) + ' ' + this.invoice.number;
      if (this.invoice.isInvoice) {
        this.documentType = 'INVOICE';
        this.btnLabel = this.$t('DOWNLOAD_INVOICE');
      } else {
        this.documentType = 'CREDIT_NOTE';
        this.btnLabel = this.$t('DOWNLOAD_CREDIT_NOTE');
      }
    },
    getComment () {
      if (this.invoice.comment !== undefined) {
        this.comment = this.invoice.comment;
      }
    },
    async getDocumentEvents () {
      try {
        const { data } = await eventService.getDocumentEvents(
          this.$route.params.id
        );
        this.documentEvents = data.events;
      } catch (err) {
        this.handleError(err);
      }
    },
    async getFileContent () {
      const { data } = await invoiceService.getDocumentFileContent(
        this.invoice.id
      );
      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
    getFailureReason (reason) {
      if (reason !== null && Object.values(this.failureEnumeration).includes(reason)) {
        return this.failureEnumeration[reason].description;
      } else {
        return reason;
      }
    },
    getEventDescription (event) {
      if (event.eventType === this.eventEnumeration.CREATION_FAILED.type) {
        return this.getFailureReason(event.descriptionParameters.reason);
      } else {
        return this.eventEnumeration[event.eventType].description(event);
      }
    },
    toogleSecondaryData () {
      this.showSecondaryData = !this.showSecondaryData;
    },
    async copyOneOffLinkToClipboard () {
      if (this.invoice.oneOffPaymentLink !== undefined) {
        await navigator.clipboard.writeText(this.invoice.oneOffPaymentLink);
        this.displayInfoSnackbar(this.$t('COPY_TO_CLIPBOARD_SUCCESS'));
      }
    }
  }
};
</script>

<style scoped>
  .notificationHistoryTable {
    min-height: 100px;
    max-height: 400px;
    overflow: auto;
  }
  .v-data-table /deep/ .sticky-header {
    position: sticky;
    top: 0;
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  }
  .v-data-table /deep/ .v-data-table__wrapper {
    overflow: unset;
  }
</style>