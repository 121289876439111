class UserMapper {
  /**
   * Map user object to UserCreateRequest back-end object
   * Be careful, UserCreateRequest is different than User back-end object returned by most of user-manager APIs
   */
  mapToKeycloakUser (keycloakUser, mainRole) {
    const user = {
      userName: keycloakUser.email,
      firstName: keycloakUser.firstName,
      lastName: keycloakUser.lastName,
      phoneNumber: keycloakUser.phoneNumber,
      email: keycloakUser.email,
      gender: keycloakUser.gender,
      phoneMaskKey: keycloakUser.phoneMaskKey,
      channels: [],
      profile: mainRole,
      userMainRole: mainRole
    };
    if (user.userMainRole.includes('CREDITOR')) {
      user.groups = keycloakUser.groups;
    }
    return user;
  }
}

export default new UserMapper();
