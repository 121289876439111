<template>
  <div>
    <slot
        name="activator"
        :on="on"
    ></slot>
    <v-dialog v-model="validationDialog" max-width="600px">
      <v-card>
        <v-card-title class="pa-4">
          <div class="text-h5 font-weight-bold force-break-word">{{ $t('TITLE_VALIDATION_DIALOG') }}</div>
          <div class="text-h6 font-weight-light force-break-word">{{ $t('SUBTITLE_VALIDATION_DIALOG') }}</div>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
                v-for="invoice of filteredInvoicesToValidate"
                :key="invoice.id">
              <v-checkbox
                  v-model="selectedInvoices"
                  :value="invoice"
              >
                <template v-slot:label>
                  <span>{{ invoice.number }} / {{ invoice.amountWithTax | currency(selectedDebtorAccount.currency) }}
                  </span>
                </template>
              </v-checkbox>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-row
              justify="space-around">
            <v-btn
                color="error"
                @click="close"
                class="my-4"
            >
              {{ $t('ACTION_CANCEL') }}
            </v-btn>
            <v-btn
                :disabled="selectedInvoices.length === 0"
                color="primary"
                @click="validate"
                class="my-4"
            >
              {{ $t('ACTION_VALIDATE') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import invoiceService from '@/services/invoiceService';
import numberMixin from '@/mixins/numberMixin';
import { mapState } from 'vuex';
import invoiceMapper from '@/mappers/invoiceMapper';

export default {
  name: 'InvoicesValidationDialog',
  mixins: [numberMixin],
  data () {
    return {
      on: {
        click: this.open
      },
      selectedInvoices: [],
      validationDialog: false
    };
  },
  props: {
    invoicesToValidate: {
      type: Array,
      default: () => []
    },
    privateEventBus: Object
  },
  methods: {
    async validate () {
      try {
        const mappedSelectedInvoices = this.selectedInvoices.map(invoice => invoiceMapper.mapToInvoiceDtoV2(invoice));
        await invoiceService.validateInvoices(mappedSelectedInvoices);
        this.displaySuccessSnackbar(this.$t('SUCCESS_MESSAGE_INVOICES_VALIDATION'));
        this.privateEventBus.$emit('reloadInvoicesList');
        this.close();
      } catch (err) {
        this.handleError(err);
      }
    },
    open () {
      this.validationDialog = true;
    },
    close () {
      this.validationDialog = false;
    }
  },
  computed: {
    ...mapState(['selectedDebtorAccount']),
    filteredInvoicesToValidate () {
      return this.invoicesToValidate.filter(({ status }) => status === 'TO_VALIDATE');
    }
  },
  watch: {
    filteredInvoicesToValidate () {
      this.selectedInvoices = this.filteredInvoicesToValidate;
    }
  }
};
</script>