export default {
  methods: {
    /**
     * Get the appropriate wording for the notificationType
     * Example: "New recurrent invoice" or "First dunning"
     * @param {String} notificationType
     */
    notificationTypeWording (notificationType) {
      return this.$t(notificationType);
    }
  }
};