<template>
  <v-card>
    <v-card-title class="d-flex justify-end">
      <v-dialog
          v-model="dialogAddAccount"
          max-width="500px"
          persistent
          no-click-animation
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-on="on"
              v-bind="attrs"
              dark
              color="primary">
            <v-icon class="mr-2">
              mdi-plus
            </v-icon>
            {{ $t('BUTTON_ADD_ACCOUNT') }}
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline text-center">
            <span class="force-break-word">{{ $t('TITLE_ADD_DEBTOR_ACCOUNT') }}</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="debtorAccountId"
              :label="$t('LABEL_DEBTOR_ACCOUNT_ID')"
              required
              :readonly="dialogAddAccountLoading"
            >
            <template slot="append-outer">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-alert-circle-outline </v-icon>
                </template>
                <span> {{ $t('TOOLTIP_ACCOUNT_ID') }} </span>
              </v-tooltip>
            </template>
            </v-text-field>

            <v-text-field
              v-model="secretKey"
              :label="$t('LABEL_SECRET_KEY')"
              required
              :readonly="dialogAddAccountLoading"
            >
            <template slot="append-outer">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-alert-circle-outline </v-icon>
                </template>
                <span> {{ $t('TOOLTIP_BUSINESS_KEY') }} </span>
                </v-tooltip>
            </template>
            </v-text-field>
          </v-card-text>
          <v-card-actions class="d-flex justify-space-around">
            <v-btn
              color="error"
              @click="closeAddAccountDialog"
              :disabled="dialogAddAccountLoading"
            >
              {{ $t('ACTION_CANCEL') }}
            </v-btn>

            <v-btn
              color="primary"
              dark
              @click="addAccountConfirm(debtorAccountId, secretKey)"
              :loading="dialogAddAccountLoading"
            >
              {{$t('ACTION_CONTINUE') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>
    <v-card-title class="col-sm-6 offset-sm-6">
      <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="$t('SEARCH')"
          single-line
          hide-details
          clearable
          class="col-6"
      ></v-text-field>
    </v-card-title>
    <v-data-table
        :headers="tableHeadersToDisplay"
        :items="debtorAccounts"
        :search="search"
    >
      <template v-slot:item.role="{ item }">
        <span v-if="['DEBTOR_ADMIN', 'DEBTOR_APPROVER', 'DEBTOR_PAYER'].includes(item.role)">
          {{ $t(`LABEL_${ item.role }`) }}
        </span>
        <span v-else>{{ item.role }}</span>
      </template>
      <template v-slot:item.contact="{ value }">
        <td v-if="value">{{ value.title }} {{ value.firstName }} {{ value.lastName }}</td>
      </template>
      <template v-slot:item.invoiceValidation="{ item }">
        <span v-if="item.debtorSettings.VALIDATION_REQUIRED.value">{{ $t('LABEL_YES') }}</span>
        <span v-else>{{ $t('LABEL_NO') }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
            class="ma-2"
            color="primary"
            small
            :dark="item.role === 'DEBTOR_ADMIN'"
            :disabled="item.role !== 'DEBTOR_ADMIN'"
            :to="{ name: 'DebtorSettingsAccountDetails', params: {
              accountId: item.billingAccountId,
              action: 'edit'
            }}"
        >
          <v-icon
              dark
              small
          >
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <!-- TODO: Check what to do for deletion, once done deplace disabled by :disabled="item.role !== 'DEBTOR_ADMIN'" AND add :dark="item.role === 'DEBTOR_ADMIN'" -->
            <v-btn
                v-on="on"
                v-bind="attrs"
                class="ma-2 "
                color="red"
                small
                disabled
                @click="deleteItem(item)"
            >
              <v-icon
                  dark
                  small
              >
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="headline text-center">
              <span class="force-break-word">{{ $t('CONFIRMATION_DELETE_ITEM') }}</span>
            </v-card-title>
            <v-card-actions class="d-flex justify-space-around">
              <v-btn color="error" dark @click="closeDelete">{{ $t('ACTION_CANCEL') }}</v-btn>
              <v-btn color="primary" dark @click="deleteItemConfirm">{{ $t('ACTION_OK') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import userService from '@/services/userService';
import tokenMixin from '@/mixins/tokenMixin';

export default {
  mixins: [tokenMixin],
  data () {
    return {
      dialogAddAccount: this.$route.query.addAccount,
      dialogConfirmAddAccount: false,
      dialogDelete: false,
      tableHeaders: [
        {
          text: this.$t('LABEL_ACCOUNT_ID'),
          value: 'accountId'
        },
        {
          text: this.$t('LABEL_ACCOUNT_NAME'),
          value: 'name'
        },
        {
          text: this.$t('LABEL_ACCOUNT_ROLE'),
          value: 'role'
        },
        {
          text: this.$t('LABEL_CONTACT_PERSON'),
          value: 'contact'
        },
        {
          text: this.$t('LABEL_INVOICE_VALIDATION'),
          value: 'invoiceValidation',
          hide: (creditor) => !creditor || !creditor.creditorSettings.VALIDATION_ENABLED.value
        },
        {
          text: this.$t('LABEL_ACTIONS'),
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ],
      search: '',
      editedIndex: -1,
      editedItem: {},
      debtorAccountId: '',
      secretKey: '',
      dialogAddAccountLoading: false
    };
  },
  computed: {
    ...mapState(['debtorAccounts', 'creditor']),
    tableHeadersToDisplay () {
      return this.tableHeaders.filter(header => !header.hide || !header.hide(this.creditor));
    }
  },
  watch: {
    dialogDelete (val) {
      val || this.closeDelete();
    }
  },
  methods: {
    deleteItem (item) {
      this.editedIndex = this.debtorAccounts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm () {
      this.debtorAccounts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    async addAccountConfirm (debtorId, secretKey) {
      try {
        this.dialogAddAccountLoading = true;
        const debtorAccountId = this.$store.state.creditor.accountId + '_' + debtorId;
        let redirectToAccountDetails = false;
        try {
          await userService.linkDebtorAccountToDebtorUser(debtorAccountId, secretKey);
          this.displaySuccessSnackbar(this.$t('SUCCESS_MESSAGE_ADD_DEBTOR_ACCOUNT'));
          redirectToAccountDetails = true;
        } catch (error) {
          this.handleError(error);
        }

        if (redirectToAccountDetails) {
          await this.refreshAppContext();

          this.$router.push({
            name: 'DebtorSettingsAccountDetails',
            params: {
              accountId: debtorAccountId
            }
          });
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.dialogAddAccountLoading = false;
      }
    },
    closeAddAccountDialog () {
      this.dialogAddAccount = false;
    },
    closeDelete () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
        this.editedIndex = -1;
      });
    }
  }
};
</script>