var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('ExportCsvButton',{attrs:{"loadingData":_vm.loading,"headers":_vm.exportableHeaders,"invoices":_vm.invoices,"filename":_vm.toValidatePage ? _vm.$t('NAME_CSV_FILE_TO_VALIDATE') : _vm.$t('NAME_CSV_FILE_TO_PAY')}}),_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":_vm.$t('SEARCH'),"single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.invoices,"show-select":this.creditor.creditorSettings.MULTIPLE_PAYMENT_ENABLED.value,"loading":_vm.loading,"search":_vm.search,"sort-by":_vm.defaultSortBy,"sort-desc":"","custom-sort":_vm.tableCustomSort},on:{"update:sortBy":function($event){_vm.defaultSortBy=$event},"update:sort-by":function($event){_vm.defaultSortBy=$event},"item-selected":function($event){return _vm.processDocumentSelection($event)},"toggle-select-all":function($event){return _vm.updateSelection($event)}},scopedSlots:_vm._u([(_vm.isOnMobileSupport)?{key:"header.data-table-select",fn:function(){return undefined},proxy:true}:null,{key:"item.refDoc",fn:function(ref){
var item = ref.item;
return [_c('InvoiceNumberWithDownloadLink',{attrs:{"invoice":item}})]}},{key:"item.issueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.issueDate))+" ")]}},{key:"item.externalRef",fn:function(ref){
var item = ref.item;
return [(item.linkedInvoices && item.linkedInvoices.length)?_c('v-list',{staticClass:"transparent"},_vm._l((item.linkedInvoices),function(invoice){return _c('v-list-item',{key:invoice.id,staticClass:"pa-0"},[_c('router-link',{attrs:{"to":{
            name: 'DebtorInvoiceDetail',
            params: { id: invoice.id }
          }}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(invoice.number))])])],1)}),1):_vm._e()]}},{key:"item.amountExclusiveOfTax",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amountExclusiveOfTax,item.currency))+" ")]}},{key:"item.amountWithTax",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amountWithTax,item.currency))+" ")]}},{key:"item.dueDate",fn:function(ref){
          var item = ref.item;
return [_c('DueDate',{attrs:{"dueDate":item.dueDate}})]}},{key:"item.temporaryOutstandingAmount",fn:function(ref){
          var item = ref.item;
return [_c('BoldAndRelativeAmount',{attrs:{"amount":item.temporaryOutstandingAmount,"isInvoice":item.isInvoice,"currency":item.currency}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(_vm.toValidatePage)?_c('InvoicesValidationDialog',{attrs:{"privateEventBus":_vm.privateEventBus,"invoicesToValidate":[ item ]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.isDebtorPayer,"small":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('ACTION_VALIDATE'))+" ")])]}}],null,true)}):_c('v-btn',{attrs:{"small":"","color":"primary","disabled":item.isCreditNote},on:{"click":function($event){return _vm.payInvoice(item)}}},[_vm._v(" "+_vm._s(_vm.$t('ACTION_PAY'))+" ")]),_c('InvoicesRejectionDialog',{attrs:{"privateEventBus":_vm.privateEventBus,"invoicesToReject":[ item ]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var rejectDialog = ref.on;
          var attrs = ref.attrs;
return [_c('v-menu',{attrs:{"bottom":"","right":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var menu = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),Object.assign({}, menu)),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{staticClass:"text-center",attrs:{"to":{ name: 'DebtorInvoiceDetail', params: { id: item.id } },"link":""}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('BUTTON_DETAILS'))}})],1),(item.status === 'TO_VALIDATE')?_c('v-list-item',_vm._g(_vm._b({staticClass:"text-center",attrs:{"disabled":_vm.isDebtorPayer,"link":""}},'v-list-item',attrs,false),Object.assign({}, rejectDialog)),[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('ACTION_REJECT'))}})],1):_vm._e(),(_vm.toValidatePage && !_vm.selectedDebtorAccount.debtorSettings.VALIDATION_REQUIRED.value && item.isInvoice)?_c('v-list-item',{staticClass:"text-center",attrs:{"link":""},on:{"click":function($event){return _vm.payInvoice(item)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.$t('ACTION_PAY'))}})],1):_vm._e()],1)],1)]}}],null,true)})],1)]}}],null,true),model:{value:(_vm.selectedDocuments),callback:function ($$v) {_vm.selectedDocuments=$$v},expression:"selectedDocuments"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }