<template>
  <v-row class="pr-3">
    <v-select
      v-model="selectedPhoneMask"
      :items="phoneMasks"
      :label="$t('REGION')"
      :disabled="disabled"
      :rules="phoneNumber && !disabled ? [rules.requiredFieldRules] : []"
      :required="phoneNumber"
      class="col-4"
    >
      <template v-slot:item="{ item }">
        <span>{{ $t(item.key) }} ({{ item.indicative }})</span>
      </template>
      <template v-slot:selection="{ item }">
        <span>{{ $t(item.key) }}</span>
      </template>
    </v-select>
    <v-text-field
      ref="phoneNumberInput"
      v-facade="selectedPhoneMask ? selectedPhoneMask.mask : ''"
      v-model="localPhoneNumber"
      :label="$t('LABEL_MOBILE_PHONE')"
      :disabled="disabled || !selectedPhoneMask"
      :rules="disabled ? [] : phoneNumberRules"
    ></v-text-field>
  </v-row>
</template>

<script>
import { facade } from 'vue-input-facade';
import phoneMaskService from '@/services/phoneMaskService';
import ruleMixin from '@/mixins/ruleMixin';
import isEmpty from 'lodash/isEmpty';
import maskMixin from '@/mixins/maskMixin';

export default {
  name: 'PhoneNumberInput',
  directives: {
    facade
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    phoneNumber: {
      type: String
    },
    phoneMaskKey: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: false
    },
    creditorId: {
      type: String
    }
  },
  mixins: [ruleMixin, maskMixin],
  data () {
    return {
      localPhoneNumber: this.phoneNumber,
      selectedPhoneMask: undefined,
      phoneMasks: []
    };
  },
  computed: {
    phoneNumberRules () {
      const rules = [];
      if (!isEmpty(this.selectedPhoneMask)) {
        rules.push(this.rules.mobilePhoneRules(new RegExp(this.selectedPhoneMask.regex)));
      }
      if (this.required) {
        rules.push(this.rules.requiredFieldRules);
      }
      return rules;
    }
  },
  watch: {
    selectedPhoneMask () {
      this.$emit('update:phoneMaskKey', this.selectedPhoneMask?.key);
    },
    localPhoneNumber () {
      this.$emit('update:phoneNumber', this.localPhoneNumber);
      this.$emit('update:isValid', this.$refs.phoneNumberInput.validate());
    }
  },
  async beforeMount () {
    const { data } = await phoneMaskService.getAllEnabledPhoneMasks(this.creditorId);
    this.phoneMasks = data;
    if (this.phoneNumber) {
      // We need to select the mask corresponding to the existing phone number
      this.selectedPhoneMask = this.phoneMasks.find(
        phoneMask => new RegExp(phoneMask.regex).test(this.replaceWithoutBlank(this.phoneNumber))
      );
    }
    this.localPhoneNumber = this.phoneNumber;
  }
};
</script>