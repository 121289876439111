<template>
  <v-card>
    <v-card-title>
      <ExportCsvButton
          :loadingData="loading"
          :headers="exportableHeaders"
          :invoices="invoices"
          :filename="$t('NAME_CSV_FILE_PAID')">
      </ExportCsvButton>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          :label="$t('SEARCH')"
          single-line
          hide-details
          clearable
          @input="getInvoices(debtorAccountId)"
      ></v-text-field>
    </v-card-title>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="invoices"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalInvoices"
          :footer-props="footerProps"
          :sort-by.sync="defaultSortBy">

          <template v-slot:item.refDoc="{ item }">
            <InvoiceNumberWithDownloadLink :invoice="item"></InvoiceNumberWithDownloadLink>
          </template>
          <template v-slot:item.externalRef="{ item }">
            <span v-if="item.linkedInvoices && item.linkedInvoices.length">
              <v-list>
                <v-list-item class="pa-0"
                v-for='linkedInvoice in item.linkedInvoices'
                :key='linkedInvoice.id'>
                  <router-link :to="{
                    name: routeDetail,
                    params: { id: linkedInvoice.id }
                  }">
                    {{ $t('INVOICE')}} {{ linkedInvoice.number }}
                  </router-link>
                </v-list-item>
              </v-list>
            </span>
            <span v-else-if="item.externalRef">{{ $t('ORDER')}} {{ item.externalRef }}</span>
          </template>
          <template v-slot:item.issueDate="{ item }">
            <span>{{ item.issueDate | date }}</span>
          </template>
          <template v-slot:item.amountExclusiveOfTax="{ item }">
            <span>{{ item.amountExclusiveOfTax | currency(item.currency) }}</span>
          </template>
          <template v-slot:item.amountWithTax="{ item }">
            <span>{{ item.amountWithTax | currency(item.currency) }}</span>
          </template>
          <template v-slot:item.dueDate="{ item }">
            <span>{{ item.dueDate | date }}</span>
          </template>
          <template v-slot:item.paymentDate="{ item }">
            <span v-if="item.isPaymentValidated">
              <v-icon color="blue" small v-if="item.isPaymentAccepted">mdi-clock-alert-outline</v-icon>{{ item.paymentDate | date }}
            </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn color="primary" :to="{
              name: routeDetail,
              params: { id: item.id } }">
              {{ $t('BUTTON_DETAILS') }}
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import invoiceService from '@/services/invoiceService';
import invoiceMapper from '@/mappers/invoiceMapper';
import dateMixin from '@/mixins/dateMixin';
import numberMixin from '@/mixins/numberMixin';
import userMixin from '@/mixins/userMixin';
import ExportCsvButton from '@/components/common/buttons/ExportCsvButton';
import InvoiceNumberWithDownloadLink from '@/components/common/buttons/InvoiceNumberWithDownloadLink';
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';

export default {
  name: 'InvoicePaidTable',
  mixins: [dateMixin, numberMixin, userMixin],
  components: {
    ExportCsvButton,
    InvoiceNumberWithDownloadLink
  },
  props: {
    debtorAccountId: {
      type: String,
      default: ''
    },
    routeDetail: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      currentUsedOptions: {},
      currentUsedDebtorAccountId: null,
      currentUsedSeachValue: '',
      totalInvoices: 0,
      options: { },
      invoices: [],
      search: '',
      loading: true,
      footerProps: {
        'items-per-page-options': [5, 10, 20, 50, 100]
      },
      defaultSortBy: 'issueDate',
      headers: [
        {
          text: this.$t('LABEL_DOCUMENT_REFERENCE'),
          value: 'refDoc',
          sortable: false,
          exportable: true
        },
        {
          text: this.$t('LABEL_ORDER_REFERENCE'),
          value: 'externalRef',
          sortable: false,
          exportable: true
        },
        {
          text: this.$t('INVOICE_ISSUE_DATE'),
          value: 'issueDate',
          sortable: false,
          align: 'center',
          exportable: true
        },
        {
          text: this.$t('LABEL_AMOUNT_EXCLUDING_TAXES'),
          value: 'amountExclusiveOfTax',
          align: 'end',
          exportable: true
        },
        {
          text: this.$t('LABEL_AMOUNT_INCLUDING_TAXES'),
          value: 'amountWithTax',
          align: 'end',
          exportable: true
        },
        {
          text: this.$t('INVOICE_DUE_DATE'),
          value: 'dueDate',
          align: 'center',
          exportable: true
        },
        {
          text: this.$t('PAYMENT_DATE'),
          value: 'paymentDate',
          sortable: false,
          align: 'center',
          exportable: true
        },
        {
          text: this.$t('LABEL_ACTIONS'),
          value: 'actions',
          sortable: false,
          exportable: false
        }
      ]
    };
  },
  computed: {
    exportableHeaders () {
      return this.headers.filter(header => !!header.exportable);
    },
    ...mapState(['selectedDebtorAccount'])
  },
  methods: {
    getInvoices: debounce(async function (debtorAccountId) {
      // Useful condition to avoid having two calls made for the same debit account and the same options (sorting, order, etc.)
      // Case that happened if we did a 'ctrl + r' on the page
      if (debtorAccountId && (this.currentUsedDebtorAccountId !== debtorAccountId ||
          this.options !== this.currentUsedOptions || this.search !== this.currentUsedSeachValue)) {
        this.currentUsedDebtorAccountId = debtorAccountId;
        this.currentUsedOptions = this.options;
        if (!this.search) {
          this.search = '';
        }
        this.currentUsedSeachValue = this.search;
        this.loading = true;
        try {
          const { data } = await invoiceService.getPaginatedPaidInvoices(debtorAccountId, this.search, this.options);
          this.totalInvoices = data.totalElements;
          this.invoices = invoiceMapper.mapAll(data.content);
        } catch (err) {
          this.handleError(err);
        } finally {
          this.loading = false;
        }
      }
    }, 500)
  },
  watch: {
    options: {
      handler () {
        return this.getInvoices(this.debtorAccountId);
      },
      deep: true
    },
    selectedDebtorAccount () {
      return this.getInvoices(this.debtorAccountId);
    }
  }
};
</script>
